import axios from '../../utils/api';

export default {
    state: {
        isLoggedIn: false,
    },
    getters: {
        isLoggedIn: (state) => state.isLoggedIn,
    },
    mutations: {
        SET_LOGGED_STATUS(state, data) {
            state.isLoggedIn = data;
        },
    },
    actions: {
        async doLogout(context) {
            // Do logout
            const response = await axios.get('/admin/api/auth/logout', {
                headers: {
                    'login-token': localStorage.getItem('token'),
                    'X-Auth-Sign': localStorage.getItem('secret'),
                }
            });

            // Clear local storage
            const lang = localStorage.getItem('lang');
            localStorage.clear();
            localStorage.setItem('lang', lang);

            context.commit('SET_LOGGED_STATUS', false);

            // Return response
            return response.data;
        },
        doLogin(context, params = {}) {
            return axios.post('/admin/api/auth/login', params)
                .then(r => {
                    return r.data;
                })
                .catch((r) => {
                    console.error(r);
                    throw new Error('Error request');
                })
        },
        do2faValidate(context, params = {}) {
            return axios.post('/admin/api/auth/validate-code', params).then((response) => {
                const data = response.data;

                if (data.status) {
                    context.commit('SET_LOGGED_STATUS', true);
                }

                return data;
            });
        },
        checkAuth(context){
            const TK = 'token';

            const getToken = () => localStorage.getItem(TK);

            context.commit('SET_LOGGED_STATUS', getToken() !== null);
        }
    },
    modules: {}
};