export default {
    login: {
        h2: "Login to account",
        placeholder: {
            email: "E-mail",
            password: "Password",
            code_email: "Enter code from email",
            code_google: "Enter code from Google Authenticator",
            code_phone: "Enter code from SMS"
        },
        forgot: "Forgot password?",
        signUp: {
            text: "Not a member?",
            link: "Sign Up",
        },
        submit: "Login",

        errors: {
            check: "Check form field",
            required: "This field is required",
            email: "This field must be a valid email",
            password: "This field must be a valid password (a-z, A-Z, 0-9) and more than 6 characters",
            codeLength: "This field must be a {l}-digit code",
            codeRequire: "2FA code is required",
        }
    },

    logout: {
        success: "Success Logout"
    },

    dashboard: {
        users: {
            list: {
                title: "Users List",
                subtitle: "",
                columns: {
                    id: 'ID',
                    role: 'Role',
                    username: 'Username',
                    email: 'E-mail',
                    firstName: 'First Name',
                    lastName: 'Last Name',
                    phone: 'Phone',
                    timeRegister: 'Sign-Up Date',
                    timeVisit: 'Last Visited',
                    statusVerification: 'Verification Status',
                    status: 'User Status'
                }
            }
        },
        operations: {
            list: {
                title: "Operations List",
                subtitle: "",
                columns: {
                    id: 'ID',
                    status: 'Status'
                }
            }
        },
        affiliates: {
            list: {
                title: "Affiliates",
                subtitle: "",
                columns: {
                    id: 'ID',
                    status: 'Status'
                }
            },
            copiedAffiliateLink: 'Affiliate link successfully copied'
        },
        currenciesFees: {
            list: {
                title: "Fees",
                subtitle: "",
                columns: {
                    currency: 'Coin',
                    deposit_fee: 'Deposit Fee',
                    withdraw_fee: 'Withdraw Fee',
                    trade_fee: 'Trade Fees'
                }
            }
        },
        emailTemplates: {
            list: {
                title: "Email Templates",
                subtitle: "Email Templates Management",
                columns: {
                    currency: 'Coin',
                    deposit_fee: 'Deposit Fee',
                    withdraw_fee: 'Withdraw Fee',
                    trade_fee: 'Trade Fees'
                }
            },
            form: {

            }
        },
        currencies: {
            list: {
                title: "Coins & Pairs",
                subtitle: "",
                columns: {
                    currency: 'Coin',
                    deposit_fee: 'Deposit Fee',
                    withdraw_fee: 'Withdraw Fee'
                }
            }
        },
        statistics: {
            title: "User Statistics",
            subtitle: "",
            total: "Total",
            verification_statuses: {
                title: "Verification Statuses",
            },
            months: {
                0: "January",
                1: "February",
                2: "March",
                3: "April",
                4: "May",
                5: "June",
                6: "July",
                7: "August",
                8: "September",
                9: "October",
                10: "November",
                11: "December",
            },
            sign_up: {
                title: "Sign up",
                label: "Users registered",
            },
            login: {
                title: "Login",
                label: "Users visit",
            },
            fees: {
                title: "Fees",
                deposit: "Deposit",
                trade: "Trade",
                withdraw: "Withdraw",
                network_fee_usd: "Network fee (usd)",
                amount_usd: "Amount (usd)",
                commission_usd: "Commissions (usd)",
            }
        },

        noRecords: "No Records",

        footer: {
            copyright: "Payment Service by"
        },
    },

    state: {
        verificationStatuses: {
            0: 'Not Verified',
            1: 'Internal Verification Started',
            2: 'Verified',
            3: 'User Started Verification',
            4: 'Rejected',
            6: 'External Verification Started',
            8: 'External Rejected',
            9: 'KYC Attempts Exceeded',
            10: 'Waiting for Questionnaire Approval',
            null: 'null'
        },
    },

    serverError: "An internal server error occurred",
    copyError: "Error in copying text"
}
