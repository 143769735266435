import axios from '../../utils/api';

export default {
    state: {
        transactions: {
            filters: {
                type: {}
            },
            list: [],
            pagination: {
                page: 0,
                totalCountPage: 0
            },
            total_count: 0
        },
        user: {
            transactions: {
                filters: {
                    type: {}
                },
                list: [],
                pagination: {
                    page: 0,
                    totalCountPage: 0
                },
                total_count: 0
            },
        },
        typesMulti: [
            {
                key: 0,
                label: 'Deposits'
            },
            {
                key: 1,
                label: 'Withdrawals'
            },
            {
                key: [0, 1],
                label: 'Deposits & Withdrawals'
            },
            {
                key: 3,
                label: 'Trades'
            },
            {
                key: 2,
                label: 'System Transaction'
            },
            {
                key: 'bot',
                label: 'Trades with bots only'
            },
            // {
            //     key: 4,
            //     label: 'Transfers'
            // },
            {
                key: 10,
                label: 'Internal Transfers'
            },
            {
                key: 5,
                label: 'Return'
            },
            {
                key: 6,
                label: 'Trading Fee'
            },
            {
                key: 7,
                label: 'Launchpad Buy Token'
            },
            {
                key: 8,
                label: 'System Launchpad Deposit'
            },
            {
                key: [9, 30, 31],
                label: 'Referral Bonus'
            },
            {
                key: 20,
                label: 'Affiliate Reward'
            },
            {
                key: 22,
                label: "Margin Trading: holding user's funds"
            },
            {
                key: 23,
                label: "Margin Trading: releasing user's funds"
            },
            {
                key: 24,
                label: "Margin Trading: from Exchange to Funding account"
            },
            {
                key: 25,
                label: "Margin Trading: from Funding account to Exchange"
            },
            {
                key: 29,
                label: "Margin Trading: Margin Call - user's funds liquidation"
            },
        ],
    },
    getters: {
        getTransactionsHistoryList: (state) => state.transactions,
        getUserTransactionsHistoryList: (state) => state.user.transactions,
        // getTransactionsHistoryTypes: (state) => state.transactions.filters.type
        getTransactionsHistoryTypes: (state) => state.typesMulti
    },
    mutations: {
        SET_TRANSACTIONS_LIST(state, data) {
            state.transactions = data
        },
        SET_USER_TRANSACTIONS_LIST(state, data) {
            state.user.transactions = data
        }
    },
    actions: {
        loadTransactionsHistoryList(context, params = {}) {
            // Do get data
            return axios.get('/admin/api/user/transactions-history', {params})
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        context.commit('SET_TRANSACTIONS_LIST', data.data.transactions);
                    }

                    return data;
                })
                .catch((error) => {
                    console.log(error);
                    throw new Error('Error request');
                });
        },
        loadUserTransactionsHistoryList(context, params = {}) {
            // Do get data
            return axios.get('/admin/api/user/transactions-history', {params})
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        context.commit('SET_USER_TRANSACTIONS_LIST', data.data.transactions);
                    }

                    return data;
                })
                .catch((error) => {
                    console.log(error);
                    throw new Error('Error request');
                });
        },
        /**
         * ALL Operation START Export
         *
         * @param context
         * @param params
         * @returns {*}
         */
        exportTransactionsHistory(context, params = {}) {
            return axios.get('/admin/api/user/transactions-history-export', { params })
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    return error.data;
                });
        },
        /**
         * ALL Operation GET File
         * @param context
         * @param params
         * @returns {*}
         */
        exportTransactionsHistoryGET(context, params = {}) {
            return axios.get('/admin/api/user/transactions-history-export', { params })
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    return error.data;
                });
        }
    },
    modules: {}
};
