import axios from '../../utils/api';

export default {
    state: {
        launchpad: {
            list: [],
            pagination: {
                page: 0,
                totalCountPage: 0
            },
            total_count: 0
        },
        launchpadParticipants: {
            list: [],
            pagination: {
                page: 0,
                totalCountPage: 0
            },
            total_count: 0
        }
    },
    getters: {
        getLaunchpadData: (state) => state.launchpad,
        getLaunchpadParticipantsData: (state) => state.launchpadParticipants,
    },
    mutations: {
        SET_LAUNCHPAD_LIST(state, data) {
            state.launchpad = data;
        },

        SET_LAUNCHPAD_PARTICIPANTS_LIST(state, data) {
            state.launchpadParticipants = data;
        },
    },
    actions: {
        /**
         * Load launchpad projects list
         *
         * @param context
         * @param params
         * @returns {*}
         */
        loadLaunchpadList(context, params = {}) {
            // Do get data
            return axios.get('/admin/api/ieo/projects', {params})
                .then((response) => {
                    const data = response.data;

                    if (data.data) {
                        context.commit('SET_LAUNCHPAD_LIST', data.data);
                    }

                    return data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        /**
         * @param context
         * @param params
         * @returns {*}
         */
        createLaunchpadProject(context, params = {}) {
            // Do get data
            return axios.post(`/admin/api/ieo/project`, params)
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    return error.data;
                });
        },

        /**
         * @param context
         * @param params
         * @returns {*}
         */
        updateLaunchpadProject(context, params = {}) {
            // Do get data
            return axios.post(`/admin/api/ieo/project?id=${params.id}`, params.data)
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    return error.data;
                });
        },

        /**
         * Update logo for launchpad project
         *
         * @param context
         * @param params
         * @returns {*}
         */
        updateLaunchpadProjectLogo(context, params = {}) {
            // Do get data
            return axios.post(`/admin/api/ieo/project?id=${params.id}`, params.data, {asFormData: true})
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    return error.data;
                });
        },

        /**
         * @param context
         * @param params
         * @returns {*}
         */
        updateLaunchpadProjectStatus(context, params = {}) {
            // Do get data
            return axios.post(`/admin/api/ieo/project-status?id=${params.id}`, params.data)
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    return error.data;
                });
        },

        /**
         * Do delete IEO project
         *
         * @param context
         * @param params
         * @returns {*}
         */
        deleteLaunchpadProject(context, params = {}) {
            return axios.delete(`/admin/api/ieo/delete-project?id=${params.id}`, {data: params})
                .then(r => {
                    return r.data;
                })
                .catch((r) => {
                    return r.data;
                })
        },

        /**
         *
         * @param context
         * @param params
         * @returns {*}
         */
        loadLaunchpadParticipantsList(context, params = {}) {
            // Do get data
            return axios.get('/admin/api/ieo/project-participants', {params})
                .then((response) => {
                    const data = response.data;

                    if (data.data) {
                        context.commit('SET_LAUNCHPAD_PARTICIPANTS_LIST', data.data);
                    }

                    return data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        /**
         * @param context
         * @param params
         * @returns {*}
         */
        addLaunchpadLink(context, params = {}) {
            return axios.post(`/admin/api/ieo/link`, params)
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    return error.data;
                });
        },

        /**
         * @param context
         * @param params
         * @returns {*}
         */
        updateLaunchpadLink(context, params = {}) {
            return axios.post(`/admin/api/ieo/link?id=${params.id}`, params.data)
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    return error.data;
                });
        },
    },
    modules: {}
};
