import axios from '../../utils/api';

export default {
    state: {
        orders: {
            filters: {
                status: {}
            },
            list: [],
            pagination: {
                page: 0,
                totalCountPage: 0
            },
            total_count: 0
        },
        ordersIsLoading: false,

        user: {
            orders: {
                filters: {
                    status: {}
                },
                list: [],
                pagination: {
                    page: 0,
                    totalCountPage: 0
                },
                total_count: 0
            },
            ordersIsLoading: false,
        },

        types: {
            0: 'Deposit',
            1: 'Withdraw',
            2: 'Internal Deposit',
            3: 'Internal Withdraw',
        },
        statuses: {
            0: '0. Created',
            1: '9. Sent (for Withdrawals) | Received (For Deposits)',
            2: 'Wait (2)',
            3: '6. Under Review',
            4: 'Processing',
            5: '7. Deposit - Waiting for Network Confirmations',
            6: 'Deleted By Admin',
            7: '2. Canceled',
            8: 'Email Code Waiting',
            9: '1. Waiting for 2FA from User',
            10: '11. Internal cryptoservice error',
            11: '8. Withdrawal - Waiting for Wallets provider response',
            12: 'Lock Process Withdraw',
            13: 'Personal Pin',
            14: 'Error Internal Refill',
            15: 'Preparing request to AML',
            16: '4. Rejected by AML service',
            18: '3. Waiting for AML response (Sent to AML system)',
            19: '5. Manually rejected by AML officer (frozen funds sent to funds@...)',
            '-1': '10. Was not processed on Wallets Provider'
        },
        // statusesMulti: [
        //     {
        //         key: 'all_important',
        //         label: 'All Important'
        //     },
        //     {
        //         key: 9,
        //         label: '1. Waiting for 2FA from User'
        //     },
        //     {
        //         key: 7,
        //         label: '2. Canceled'
        //     },
        //     {
        //         key: 15,
        //         label: '3. Preparing request to AML'
        //     },
        //     {
        //         key: 18,
        //         label: '4. Waiting for AML response (Sent to AML system)'
        //     },
        //     {
        //         key: 16,
        //         label: '5. Rejected by AML service'
        //     },
        //     {
        //         key: 19,
        //         label: '6. Manually rejected by AML officer (frozen funds sent to funds@...)'
        //     },
        //     {
        //         key: 3,
        //         label: '7. Under Review'
        //     },
        //     {
        //         key: 5,
        //         label: '8. Deposit - Waiting for Network Confirmations'
        //     },
        //     {
        //         key: 11,
        //         label: '9. Withdrawal - Waiting for Wallets provider response'
        //     },
        //     {
        //         key: 1,
        //         label: '10. Sent (for Withdrawals) | Received (For Deposits)'
        //     },
        //     {
        //         key: '-1',
        //         label: '11. Was not processed on Wallets Provider'
        //     },
        //     {
        //         key: 10,
        //         label: '12. Internal cryptoservice error'
        //     },
        // ]
    },
    getters: {
        getCoinOrdersList: (state) => state.orders,
        getUserCoinOrdersList: (state) => state.user.orders,
        // getUserCoinOrdersListCount: (state) => state.user.orders.total_count,
        getCoinOrdersTypes: (state) => state.types,
        getCoinOrdersStatuses: (state) => state.statuses,

        getCoinOrdersIsLoading: (state) => state.ordersIsLoading,
    },
    mutations: {
        SET_COIN_ORDERS_LIST(state, data) {
            state.orders = data
        },
        SET_COIN_ORDERS_LOADING(state, data) {
            state.ordersIsLoading = data;

            if (data) {
                state.orders.total_count = 0
            }
        },
        SET_USER_COIN_ORDERS_LIST(state, data) {
            state.user.orders = data
        },
        SET_USER_COIN_ORDERS_LOADING(state, data) {
            state.user.ordersIsLoading = data;

            if (data) {
                state.user.orders.total_count = 0
            }
        },
    },
    actions: {
        loadCoinOrdersList(context, params = {}) {
            context.commit('SET_COIN_ORDERS_LOADING', true);

            // Do get data
            return axios.get('/admin/api/transactions/list', {params})
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        context.commit('SET_COIN_ORDERS_LIST', data.data);
                        context.commit('SET_COIN_ORDERS_LOADING', false);
                    }

                    return data;
                })
                .catch((error) => {
                    console.log(error);

                    context.commit('SET_COIN_ORDERS_LOADING', false);

                    throw new Error('Error request');
                });
        },
        loadUserCoinOrdersList(context, params = {}) {
            context.commit('SET_USER_COIN_ORDERS_LOADING', true);

            // Do get data
            return axios.get('/admin/api/transactions/list', {params})
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        context.commit('SET_USER_COIN_ORDERS_LIST', data.data);
                        context.commit('SET_USER_COIN_ORDERS_LOADING', false);
                    }

                    return data;
                })
                .catch((error) => {
                    console.log(error);

                    context.commit('SET_USER_COIN_ORDERS_LOADING', false);

                    throw new Error('Error request');
                });
        },

        /**
         * Get fiat order detail
         *
         * @param context
         * @param id
         * @returns {*}
         */
        loadDepositOrderFiatDetail(context, id = null) {
            return axios.get(`/admin/api/transactions/fiat-deposit-proof?_id=${id}`)
                .then(r => {
                    return r.data;
                })
                .catch((r) => {
                    console.error(r);
                    throw new Error('Error request');
                })
        },
        /**
         * Create fiat deposit
         *
         * @param context
         * @param payload
         * @returns {*}
         */
        createCoinOrder(context, payload = {}) {
            // Do send data
            return axios.post(`/admin/api/fiat/create-deposit`, payload)
                .then(r => {
                    return r.data;
                })
                .catch((r) => {
                    console.error(r);
                })
        },

        /**
         * Confirm created fiat deposit
         *
         * @param context
         * @param payload
         * @returns {*}
         */
        confirmCreateCoinOrder(context, id = null) {
            // Do send data
            return axios.post(`/admin/api/transactions/confirm-fiat-deposit?id=${id}`)
                .then(r => {
                    return r.data;
                })
                .catch((r) => {
                    console.error(r);
                })
        },
        /**
         * @param context
         * @param payload
         * @returns {*}
         */
        confirmCoinOrder(context, payload = {}) {
            const inputs = Object.assign({}, payload);
            delete inputs.id;

            // Do send data
            // return axios.post(`/admin/api/coin-orders/confirm?id=${payload.id}`, inputs)
            return axios.post(`/admin/api/transactions/confirm?_id=${payload.id}`, inputs, {asFormData: payload.file})
                .then(r => {
                    return r.data;
                })
                .catch((r) => {
                    console.error(r);
                    throw new Error('Error request');
                })
        },
        /**
         * @param context
         * @param payload
         * @returns {*}
         */
        cancelCoinOrder(context, payload = {}) {
            const inputs = Object.assign({}, payload);
            delete inputs.id;

            // Do sen data
            // return axios.post(`/admin/api/coin-orders/delete?id=${payload.id}`, inputs)
            return axios.post(`/admin/api/transactions/cancel?_id=${payload.id}`, inputs)
                .then(r => {
                    return r.data;
                })
                .catch((r) => {
                    console.error(r);
                    throw new Error('Error request');
                })
        },
        exportCoinOrdersPOST(context, params = {}) {
            // Do get data
            return axios.post('/admin/api/coin-orders/export', params)
                .then((response) => {
                    const data = response.data;
                    return data;
                })
                .catch((error) => {
                    console.log(error);
                    throw new Error('Error request');
                });
        },
        exportCoinOrdersGET(context, params = {}) {
            // Do get data
            return axios.get('/admin/api/coin-orders/export', { params })
                .then((response) => {
                    const data = response.data;
                    return data;
                })
                .catch((error) => {
                    console.log(error);
                    throw new Error('Error request');
                });
        },
        /**
         *
         * @param context
         * @param payload
         * @returns {*}
         */
        refundCoinOrder(context, payload = {}) {
            return axios.post(`/admin/api/coin-orders/refund?id=${payload.id}`, payload.data)
                .then(r => {
                    return r.data;
                })
                .catch((r) => {
                    return r.data;
                })
        },
    },
    modules: {}
};
