import axios from '../../utils/api';
import {buildQueryString} from "@/utils/BuildQueryString";

export default {
    state: {
        trades: {
            list: [],
            pagination: {
                page: 0,
                totalCountPage: 0
            },
            total_count: 0
        },

        tradeOrders: {
            active: {
                list: [],
                pagination: {
                    page: 0,
                    totalCountPage: 0
                },
                total_count: 0
            },
            history: {
                list: [],
                pagination: {
                    page: 0,
                    totalCountPage: 0
                },
                total_count: 0
            }
        },
        tradeOrdersActiveIsLoading: false,
        tradeOrdersClosedIsLoading: false,

        tradeSides: {
            0: 'Buy',
            1: 'Sell'
        },
        tradeTypes: {
            0: "Limit",
            1: "Market",
            2: "Stop-limit",
        },
        tradeStatuses: {
            '-1': 'Error',
            0: 'In process',
            1: 'Public',
            2: 'Done',
            3: 'Delete',
            6: 'In creating'
        },

        tradesAll: {
            active: {
                list: [],
                pagination: {
                    page: 0,
                    totalCountPage: 0
                },
                total_count: 0
            },
            history: {
                list: [],
                pagination: {
                    page: 0,
                    totalCountPage: 0
                },
                total_count: 0
            }
        },
        tradesAllIsLoading: false,
    },
    getters: {
        getTradeOrdersActiveList: (state) => state.tradeOrders.active.list,
        getTradeOrdersActiveListPagination: (state) => state.tradeOrders.active.pagination,
        getTradeOrdersActiveListCount: (state) => state.tradeOrders.active.total_count,
        getTradeOrdersActiveIsLoading: (state) => state.tradeOrdersActiveIsLoading,

        getTradeOrdersHistoryList: (state) => state.tradeOrders.history.list,
        getTradeOrdersHistoryListPagination: (state) => state.tradeOrders.history.pagination,
        getTradeOrdersHistoryListCount: (state) => state.tradeOrders.history.total_count,
        getTradeOrdersClosedIsLoading: (state) => state.tradeOrdersClosedIsLoading,

        /**
         * @param state
         * @returns {[]}
         */
        getUserTradesList: (state) => state.trades?.list,
        getUserTradesListPagination: (state) => state.trades?.pagination,

        /**
         * @param state
         * @returns {{}}
         */
        getTradesAll: (state) => state.tradesAll,
        getTradesAllIsLoading: (state) => state.tradesAllIsLoading,

        /**
         * @param state
         * @returns {{}}
         */
        getTradeTypes: (state) => state.tradeTypes,
        getTradeSides: (state) => state.tradeSides,
        getTradeStatuses: (state) => state.tradeStatuses,
    },
    mutations: {
        SET_TRADE_ORDERS_ACTIVE_LIST(state, data) {
            state.tradeOrders.active = data.active
        },
        SET_TRADE_ORDERS_ACTIVE_LOADING(state, data) {
            state.tradeOrdersActiveIsLoading = data;

            if(data){
                state.tradeOrders.active.total_count = 0
            }
        },

        SET_TRADE_ORDERS_CLOSED_LIST(state, data) {
            state.tradeOrders.history = data.history;
        },
        SET_TRADE_ORDERS_CLOSED_LOADING(state, data) {
            state.tradeOrdersClosedIsLoading = data;

            if(data){
                state.tradeOrders.history.total_count = 0
            }
        },
        SET_TRADES_ALL(state, data) {
            state.tradesAll = data
        },
        SET_TRADES_ALL_LOADING(state, data) {
            state.tradesAllIsLoading = data;
        },
        SET_USER_TRADES(state, data) {
            state.trades = data;
        },
        SET_TRANSACTIONS_HISTORY_EXPORT(state, data) {
            state.tradesExport = data
        },
    },
    actions: {
        /**
         * @param context
         * @param params
         */
        loadUserTrades(context, params = {}) {
            // Do get data
            axios.get(`/admin/api/user/trades-history`, {params})
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        context.commit('SET_USER_TRADES', data.data);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    if (error) {
                        throw new Error('Error request');
                    }
                });
        },

        /**
         * type
         * currency_pair_id
         * status
         * type_trade
         *
         * @param context
         * @param payload
         * @returns {*}
         */
        loadTradeOrdersActiveList(context, payload = {}) {
            context.commit('SET_TRADE_ORDERS_ACTIVE_LOADING', true);

            const queryString = buildQueryString(payload);

            // Do get data
            return axios.get(`/admin/api/user/orders-history?${queryString}`)
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        context.commit('SET_TRADE_ORDERS_ACTIVE_LIST', data.data);
                        context.commit('SET_TRADE_ORDERS_ACTIVE_LOADING', false);
                    }

                    return data;
                })
                .catch((error) => {
                    console.log(error);

                    context.commit('SET_TRADE_ORDERS_ACTIVE_LOADING', false);
                });
        },

        /**
         *
         * @param context
         * @param payload
         * @returns {*}
         */
        loadTradeOrdersClosedList(context, payload = {}) {
            context.commit('SET_TRADE_ORDERS_CLOSED_LOADING', true);

            const queryString = buildQueryString(payload);

            // Do get data
            return axios.get(`/admin/api/user/orders-history?${queryString}`)
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        context.commit('SET_TRADE_ORDERS_CLOSED_LIST', data.data);
                        context.commit('SET_TRADE_ORDERS_CLOSED_LOADING', false);
                    }

                    return data;
                })
                .catch((error) => {
                    console.log(error);

                    context.commit('SET_TRADE_ORDERS_CLOSED_LOADING', false);
                });
        },

        /**
         * @param context
         * @param params
         */
        loadTradeOrdersAllList(context, params = {}) {
            // Do get data
            axios.get(`/admin/api/user/all-orders`, {params})
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        context.commit('SET_TRADES_ALL', data.data);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    throw new Error('Error request');
                });
        },

        exportAllTrades(context, params = {}) {
            // Do get data
            return axios.get('/admin/api/trades/export-all', {params})
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        context.commit('SET_TRADES_EXPORT', data.data);
                    }

                    return data;
                })
                .catch((error) => {
                    console.log(error);
                    throw new Error('Error request');
                });
        },
        exportClosedOrdersPOST(context, params = {}) {
            // Do get data
            return axios.post('/admin/api/trades/export-closed-orders', params)
                .then((response) => {
                    const data = response.data;
                    return data;
                })
                .catch((error) => {
                    console.log(error);
                    throw new Error('Error request');
                });
        },
        exportClosedOrdersGET(context, params = {}) {
            // Do get data
            return axios.get('/admin/api/trades/export-closed-orders', {
                params
            })
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                    throw new Error('Error request');
                });
        },
        /**
         *  can_trade  (int) 0 - can trade, 1 - can't trade
         *
         * @returns {*}
         */
        switchTradingStatus(context, payload = {}){
            const inputs = Object.assign({}, payload);
            delete inputs.id;

            // Do get data
            return axios.post(`/admin/api/user/change-trade-status?user_id=${payload.id}`, inputs)
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                    throw new Error('Error request');
                });
        }
    },
    modules: {}
};
