import { createI18n } from "vue-i18n";

const files = require.context('.', false, /\.js$/);
const messages = {};
messages.available = {};

files.keys().forEach((key) => {
    if (key === './index.js') return;

    messages[key.replace(/(\.\/|\.js)/g, '').toUpperCase()] = files(key).default;
    messages.available[key.replace(/(\.\/|\.js)/g, '')] = key.replace(/(\.\/|\.js)/g, '')
});

const i18n = createI18n({
    locale: localStorage.getItem('lang') || 'EN', // set locale
    fallbackLocale: 'EN', // set fallback locale
    legacy: false,

    messages, // set locale messages
})

export default i18n;
