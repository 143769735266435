import axios from '../../utils/api';

export default {
    state: {
        bonusPrograms: [],
        bonuses: {
            list: [],
            totalPages: 1,
            page: 0
        },
    },
    getters: {
        getBonusPrograms: state => state.bonusPrograms,
        getBonusProgramsBonuses: state => state.bonuses.list,
    },
    mutations: {
        SET_BONUS_PROGRAMS_LIST(state, data) {
            state.bonusPrograms = data;
        },
        SET_BONUS_PROGRAMS_BONUSES_LIST(state, data) {
            state.bonuses = {
                list: data.currentPageResult,
                totalPages: data.totalPages,
                page: data.page
            };
        },
    },
    actions: {
        loadBonusProgramsBonuses(context, params = {}) {
            // Do get data
            return axios.get('/v3/referral-service/bonus-program/bonuses', {params})
                .then((response) => {
                    const data = response.data;

                    if (data.data) {
                        context.commit('SET_BONUS_PROGRAMS_BONUSES_LIST', data.data);
                    }

                    return data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        /**
         * {"ids": [364, 354]}
         *
         * @param context
         * @param params
         * @returns {*}
         */
        payBonusProgramsBonuses(context, params = {}) {
            return axios.put(`/v3/referral-service/bonus-program/pay-bonuses`, params)
                .then(r => {
                    return r.data;
                })
                .catch((r) => {
                    return r.data;
                })
        },

        /**
         *
         * @param context
         * @param params
         * @returns {*}
         */
        loadBonusPrograms(context, params = {}) {
            // Do get data
            return axios.get('/v3/referral-service/bonus-program/programs', {params})
                .then((response) => {
                    const data = response.data;

                    if (data.data) {
                        context.commit('SET_BONUS_PROGRAMS_LIST', data.data);
                    }

                    return data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        /**
         * bonus_amount
         * referrals_minimal_trading_volume_usdt
         * bonus_currency_id
         * end_at_ts
         * start_at_ts
         *
         * @param context
         * @param params
         */
        createBonusProgram(context, params = {}) {
            // Do send data
            return axios.post(`/v3/referral-service/bonus-program/program`, params)
                .then(r => {
                    return r.data;
                })
                .catch((r) => {
                    return r.data;
                })
        },

        /**
         * {"id": 123, "bonus_amount": "10.01", "referrals_minimal_trading_volume_usdt": "10.02", "bonus_currency_id": 451, "end_at_ts": 1, "start_at_ts": 2}
         *
         * @param context
         * @param params
         */
        updateBonusProgram(context, params = {}) {
            // Do send data
            return axios.patch(`/v3/referral-service/bonus-program/program`, params)
                .then(r => {
                    return r.data;
                })
                .catch((r) => {
                    console.error(r);
                    return r.data;
                })
        },

        /**
         *
         * @param context
         * @param params
         */
        deleteBonusProgram(context, params = {}) {
            return axios.delete(`/v3/referral-service/bonus-program/program`, {data: params})
                .then(r => {
                    return r.data;
                })
                .catch((r) => {
                    console.error(r);
                })
        },

        /**
         * @param context
         * @param params
         * @returns {*}
         */
        stopBonusProgram(context, params = {}) {
            return axios.put(`/v3/referral-service/bonus-program/program-stop`, params)
                .then(r => {
                    return r.data;
                })
                .catch((r) => {
                    return r.data;
                })
        },
    },
    modules: {},
};
