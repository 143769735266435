import axios from '../../utils/api';
import {buildQueryString} from "@/utils/BuildQueryString";
import {formatNumCurrency} from "@/utils/Formatter";

export default {
    state: {
        /**
         *
         */
        users: {
            list: [],
            pagination: {
                page: 0,
                totalCountPage: 0
            },
            total_count: 0,
            filters: {}
        },
        userStatuses: {
            0: 'Not activated',
            1: 'Active',
            '-1': 'Banned',
            '-2': 'Banned by Not Me'
        },
        userStatistics: {},
        userSecurity: {},
        userPermissions: null,

        modal: {
            tab_key: 'basic'
        },

        /**
         *
         */
        documents: {
            list: [],
            pagination: {
                page: 0,
                totalCountPage: 0
            },
            total_count: 0
        },
        documentTypes: {
            0: 'Passport',
            1: "Driver's Licence",
            2: 'ID Card',
            3: 'PoA',
            4: 'Utilities',
            5: 'International Passport',
            6: 'Other Document',
            7: 'Certificate Of Register',
            8: 'Memorandum',
            9: 'Ownership',
            10: 'PoA',
            11: 'Directors',
            12: 'Beneficiary',
            13: 'Business Address',
            14: 'Certificate Of Incumbency',
            15: 'Selfie with a Document',
        },
        documentStatuses: {
            0: 'Uploaded',
            1: 'Approved',
            2: 'Deleted',
            3: 'Rejected',
            4: 'Waiting for Verification',
            5: 'Upload Error',
            6: 'Approved External',
            8: 'Rejected External'
        },
        profile: {
            user: {},
            verification: null,
            verification_points: null,
            address: null,
        },
        loginHistory: {
            list: [],
            pagination: {
                page: 0,
                totalCountPage: 0
            }
        },

        /**
         *
         */
        verifications: {
            list: [],
            pagination: {
                page: 0,
                totalCountPage: 0
            }
        },
        verificationStatuses: {
            0: 'Not Verified',
            1: 'Internal Verification Started',
            2: 'Verified',
            3: 'User Started Verification',
            4: 'Rejected',
            6: 'External Verification Started',
            8: 'External Rejected',
            9: 'KYC Attempts Exceeded',
            10: 'Waiting for Questionnaire Approval',
            // '-1': 'Restore All Verification Attempts',
            // '-2': 'Reset Verification Attempts',
        },
        accountStatuses: [
            { key: 1, label: 'Not opened (ML)' },
            { key: 2, label: 'Not opened (TF)' },
            { key: 4, label: 'Suspended' },
            { key: 5, label: 'Closed (customer)' },
            { key: 6, label: 'Closed (ML)' },
            { key: 7, label: 'Closed (TF)' },
            { key: 8, label: 'Not Verified' },
            { key: 3, label: 'Active' },
        ],

        /**
         * verification Questionnaire
         */
        questionnaireSettings: {},
        min_risk_level_for_ban: null,

        verificationUpdatesHistory: {
            list: [],
            pagination: {
                page: 0,
                totalCountPage: 0
            },
            total_count: 0
        },

        verificationVersionHistory: {
            list: [],
            pagination: {
                page: 0,
                totalCountPage: 0
            },
            total_count: 0
        },

        user_risk_level_labels: null,
        status2FA: null,
        riskInfo: null,
        totalBalance: null,

        userHoldsList: {
            list: [],
            pagination: {
                page: 0,
                totalCountPage: 0
            },
            total_count: 0
        },
        holdsTypes: {
            0: 'Order',
            2: 'Withdraw crypto',
            3: 'IEO coin',
            4: 'System',
            5: 'Withdraw fiat',
            7: 'Deposit',
        },
        riskLabels: {
            user_risk_level_labels: [],
            user_risk_level_items_labels: [],
        },
        userRolesList: {},

        all_qre_fields: []
    },
    getters: {
        /**
         * @param state
         * @returns {{}}
         */
        getUsersList: (state) => state.users,
        getUserProfile: (state) => state.profile,
        getUserStatuses: (state) => state.userStatuses,
        getUserStatistics: (state) => state.userStatistics,
        getUserSecurity: (state) => state.userSecurity,
        getUserPermissions: (state) => state.userPermissions,
        getUserRolesList: (state) => state.userRolesList,
        getUserRolesListFlip: (state) => state.userRolesListFlip,

        /**
         *
         * @param state
         * @returns {[]}
         */
        getUserLoginHistoryList: (state) => state.loginHistory.list,
        getUserLoginHistoryListPagination: (state) => state.loginHistory.pagination,

        /**
         *
         * @param state
         * @returns {string}
         */
        getUserTotalBalanceUSDT: (state) => formatNumCurrency(state.totalBalance?.usdt ?? 0, 0, 0),

        /**
         *
         * @param state
         * @returns {[]}
         */
        getUserDepositAndWithdrawalsStatistic: (state) => state.depositAndWithdrawalsStatistic,

        /**
         *
         * @param state
         * @returns {[]}
         */
        getUserCommentsList: (state) => state.comment?.list,
        getUserCommentsListPagination: (state) => state.comment?.pagination,

        /**
         *
         * @param state
         * @returns {[]}
         */
        getUserReferralsList: (state) => state.referrals?.list?.list,
        getUserReferralsListCount: (state) => state.referrals?.list?.count,

        getAccountStatuses: (state) => state.accountStatuses,

        /**
         *
         * @param state
         * @returns {[]}
         */
        getUserDocumentsList: (state) => state.documents.list,
        getUserDocumentsListPagination: (state) => state.documents.pagination,
        getUserDocumentsListCount: (state) => state.documents.total_count,
        getUserDocumentTypes: (state) => state.documentTypes,
        getUserDocumentStatuses: (state) => state.documentStatuses,

        /**
         *
         * @param state
         * @returns {{}}
         */
        getUserVerifications: (state) => state.verifications,
        getUserVerificationStatuses: (state) => state.verificationStatuses,
        getUserExternalVerificationHistory: (state) => state.externalVerificationHistory,
        getVerificationQuestionnaireSettings: (state) => state.questionnaireSettings,
        getVerificationQuestionnaireMinRiskLevelForBan: (state) => state.min_risk_level_for_ban,
        getVerificationsUpdatesHistoryList: (state) => state.verificationUpdatesHistory,
        getVerificationVersionHistory: (state) => state.verificationVersionHistory,

        getUserHoldsList: (state) => state.userHoldsList,
        getHoldsTypes: (state) => state.holdsTypes,

        getStatus2FA: (state) => state.status2FA,
        getRiskInfo: (state) => state.riskInfo,
        getRiskLevelLabelsSettings: (state) => state.riskLabels,

        getAllQuestionnaireFields: (state) => state.all_qre_fields,
        getUserModalTabKey: (state) => state.modal.tab_key,
    },
    mutations: {
        SET_USER_MODAL_TAB_KEY(state, data) {
            state.modal.tab_key = data;
        },
        SET_USERS(state, data) {
            state.users = data;
        },
        SET_USER_DATA(state, data) {
            state.profile = {
                user: data.model,
                verification: data.verification_model,
                verification_points: data.verification_points,
                address: data.address_model
            }

            state.loginHistory = {
                list: data.history_login.list,
                pagination: data.history_login.pagination
            }

            state.externalVerificationHistory = data.history_external_verification;

            state.riskInfo = data.risk_info;

            state.totalBalance = data.totalBalance;

            state.depositAndWithdrawalsStatistic = data.depositAndWithdrawalsStatistic;

            state.userSecurity = data.userSecurity;

            state.all_qre_fields = data.all_qre_fields;
        },
        SET_USER_VERIFICATION_DOCUMENTS(state, data) {
            state.documents = data;
        },
        CLEAR_USER_DATA(state) {
            state.profile = {
                user: {},
                verification: null,
                verification_points: null,
                address: null
            }

            state.loginHistory = {
                list: [],
                pagination: {
                    page: 0,
                    totalCountPage: 0
                }
            };

            state.comment = {
                list: [],
                pagination: {
                    page: 0,
                    totalCountPage: 0
                }
            };

            state.riskInfo = null;

            state.totalBalance = null;

            state.depositAndWithdrawalsStatistic = null;
        },

        SET_USER_VERIFICATIONS(state, data) {
            state.verifications = data;
        },
        SET_VERIFICATION_QUESTIONNAIRE_SETTINGS(state, data) {
            state.questionnaireSettings = data;
        },
        SET_VERIFICATION_QUESTIONNAIRE_MIN_RISK_LEVEL_FOR_BAN(state, data) {
            state.min_risk_level_for_ban = data;
        },
        SET_RISK_LEVEL_LABEL_SETTINGS(state, data) {
            state.riskLabels = {
                user_risk_level_labels: data.user_risk_level_labels,
                user_risk_level_items_labels: data.user_risk_level_items_labels,
            };
        },
        SET_USER_VERIFICATION_DATA(state, data) {
            state.profile.verification = data;
            // state.profile.verification_points = null;

            if ('country_of_residence' in data) {
                state.profile.user.country_of_residence = data.country_of_residence
            }
        },
        SET_USER_VERIFICATION_POINTS(state, data) {
            state.profile.verification_points = data;
        },

        SET_STATUS_2FA(state, data) {
            state.status2FA = data;
        },

        SET_VERIFICATIONS_UPDATES_HISTORY_LIST(state, data) {
            state.verificationUpdatesHistory = data;
        },
        SET_VERIFICATIONS_VERSION_HISTORY_LIST(state, data) {
            state.verificationVersionHistory = data;
        },

        ADD_COMMENT(state, data) {
            state.comment = {
                ...state.comment,
                list: [...state.comment.list, data].sort((a, b) => b.time_create - a.time_create),
            }
        },
        SET_COMMENTS(state, data) {
            state.comment = {
                list: data.comment.list,
                pagination: data.comment.pagination
            }
        },

        SET_REFERRALS(state, data) {
            state.referrals = data;
        },

        SET_TOTAL_BALANCE(state, data) {
            state.totalBalance = data;
        },

        SET_USER_HOLDS_LIST(state, data) {
            state.userHoldsList = data;
        },

        SET_USER_ROLE(state, data) {
            state.userPermissions = data;
        },
        SET_USER_ROLES_LIST(state, data) {
            state.userRolesList = data;

            if (data) {
                const flippedObject = {};

                for (const key in data) {
                    flippedObject[data[key]] = key;
                }

                state.userRolesListFlip = flippedObject;
            }
        }
    },
    actions: {
        updateUserModalTabKey(context, data) {
            context.commit('SET_USER_MODAL_TAB_KEY', data);
        },
        loadUsersList(context, params = {}) {
            // Do get data
            return axios.get('/admin/api/user/list', { params })
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        context.commit('SET_USERS', data.data);
                    }

                    return data;
                })
                .catch((error) => {
                    console.log(error);
                    throw new Error('Error request');
                });
        },
        loadUserData(context, params = {}) {
            // Do get data
            return axios.get('/admin/api/user/find-one', { params })
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        context.commit('SET_USER_DATA', data.data);
                    }

                    return data;
                })
                .catch((error) => {
                    console.log(error);
                    throw new Error('Error request');
                });
        },

        /**
         * user_search=
         * owner_search=
         * user_id=
         * owner_id=
         * page=
         * time_create в формате “ISO_FROM - ISO_TO”
         *
         * @param context
         * @param params
         */
        loadVerificationsUpdateHistory(context, params = {}) {
            // Do get data
            axios.get('/admin/api/verification/updates-history', { params })
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        context.commit('SET_VERIFICATIONS_UPDATES_HISTORY_LIST', data.data.updates_history);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    throw new Error('Error request');
                });
        },
        /**
         *
         * @param context
         * @param params
         */
        loadVerificationVersionHistory(context, params = {}) {
            axios.get('/admin/api/verification/versions-history', { params })
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        context.commit('SET_VERIFICATIONS_VERSION_HISTORY_LIST', data.data.history);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        loadVerificationData(context, params = {}) {
            // Do get data
            axios.get(`/admin/api/verification?user_id=${params.id}`)
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        if (params.country_of_residence) {
                            context.commit('SET_USER_VERIFICATION_DATA', {
                                ...data.data.verification,
                                country_of_residence: params.country_of_residence
                            });
                        } else {
                            context.commit('SET_USER_VERIFICATION_DATA', data.data.verification);
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                    throw new Error('Error request');
                });
        },

        changeUserStatus(context, payload = {}) {
            const inputs = Object.assign({}, payload);
            delete inputs.id;

            // Do send data
            return axios.post(`/admin/api/user/change-status?id=${payload.id}`, inputs)
                .then(r => {
                    return r.data;
                })
                .catch((r) => {
                    console.error(r);
                    throw new Error('Error request');
                });
        },
        async changeUserDataValue(context, payload = {}) {
            const data = Object.assign({}, payload);
            delete data.id;

            // Do send data
            return await axios.post(`/admin/api/user/update?id=${payload.id}`, data)
                .then(r => {
                    return r.data;
                })
                .catch((r) => {
                    console.error(r);
                    throw new Error('Error request');
                });
        },
        clearUserData(context) {
            context.commit('CLEAR_USER_DATA');
        },
        loadUserVerificationDocuments(context, params = {}) {
            // Do get data
            axios.get('/admin/api/verification/documents', { params })
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        context.commit('SET_USER_VERIFICATION_DOCUMENTS', data.data.documents);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    throw new Error('Error request');
                });
        },
        updateVerificationDocument(context, payload = {}) {
            const inputs = Object.assign({}, payload);
            delete inputs.id;

            // Do send data
            return axios.post(`/admin/api/verification/document-status?id=${payload.id}`, inputs)
                .then(r => {
                    return r.data;
                })
                .catch((error) => {
                    console.error(error);
                    throw new Error('Error request');
                })
        },
        approveUserQuestionnaire(context, id) {
            // Do send data
            return axios.post(`/admin/api/verification/approve-qre?user_id=${id}`)
                .then(r => {
                    return r.data;
                })
                .catch((error) => {
                    console.error(error);
                    throw new Error('Error request');
                })
        },
        disapproveUserQuestionnaire(context, id) {
            // Do send data
            return axios.post(`/admin/api/verification/disapprove-qre?user_id=${id}`)
                .then(r => {
                    return r.data;
                })
                .catch((error) => {
                    console.error(error);
                    throw new Error('Error request');
                })
        },
        updateVerificationQuestionnaire(context, payload = {}) {
            const inputs = Object.assign({}, payload);
            delete inputs.id;
            delete inputs.signal;

            // Do send data
            return axios.post(`/admin/api/verification/update?user_id=${payload.id}`, inputs, { signal: payload.signal })
                .then(r => {
                    if (r.data.status) {
                        context.commit('SET_USER_VERIFICATION_DATA', r.data.data.verification);
                        context.commit('SET_USER_VERIFICATION_POINTS', r.data.data.verification_points);
                    }

                    return r.data;
                })
                .catch((error) => {
                    console.error(error);
                    throw new Error('Error request');
                })
        },
        updateVerificationStatus(context, payload = {}) {
            const inputs = Object.assign({}, payload);
            delete inputs.id;

            // Do send data
            return axios.post(`/admin/api/verification/set-verification-status?user_id=${payload.id}&status=${inputs.status}`, inputs)
                .then(r => {
                    return r.data;
                })
                .catch((error) => {
                    console.error(error);
                    throw new Error('Error request');
                })
        },
        updateRiskLevel(context, payload = {}) {
            const inputs = Object.assign({}, payload);
            delete inputs.id;

            // Do send data
            return axios.post(`/admin/api/user/update-risk-level?id=${payload.id}`, inputs)
                .then(r => {
                    return r.data;
                })
                .catch((error) => {
                    console.error(error);
                    throw new Error('Error request');
                })
        },
        loadRiskLevelLabelsSettings(context) {
            // Do send data
            return axios.get(`/admin/api/verification/risk-level-labels-settings`)
                .then(r => {
                    const data = r.data;

                    if (data.status) {
                        context.commit('SET_RISK_LEVEL_LABEL_SETTINGS', data.data);
                    }

                    return r.data;
                })
                .catch((error) => {
                    console.error(error);
                    throw new Error('Error request');
                })
        },
        updateRiskLevelLabelSettings(context, payload = {}) {
            // Do send data
            return axios.post(`/admin/api/verification/risk-level-labels-settings`, payload)
                .then(r => {
                    return r.data;
                })
                .catch((error) => {
                    console.error(error);
                    throw new Error('Error request');
                })
        },

        updateAccountStatus(context, payload = {}) {
            const inputs = Object.assign({}, payload);
            delete inputs.id;

            // Do send data
            return axios.post(`/admin/api/user/update-account-status?id=${payload.id}`, inputs)
                .then(r => {
                    return r.data;
                })
                .catch((error) => {
                    console.error(error);
                    throw new Error('Error request');
                })
        },
        getQuestionnaireSettings(context) {
            // Do get data
            return axios.get('/admin/api/verification/questionnaire-settings')
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        context.commit('SET_VERIFICATION_QUESTIONNAIRE_MIN_RISK_LEVEL_FOR_BAN', data.data.min_risk_level_for_ban);
                        context.commit('SET_VERIFICATION_QUESTIONNAIRE_SETTINGS', data.data.settings);
                    }

                    return data;
                })
                .catch((error) => {
                    console.log(error);
                    throw new Error('Error request');
                });
        },
        updateQuestionnaireSettings(context, payload = {}) {
            // Do send data
            return axios.post('/admin/api/verification/questionnaire-settings', payload)
                .then(r => {
                    return r.data;
                })
                .catch((error) => {
                    console.error(error);
                    throw new Error('Error request');
                })
        },
        updateRiskLevelForBan(context, payload = {}) {
            // Do send data
            return axios.post('admin/api/verification/risk-level-for-ban', payload)
                .then(r => {
                    return r.data;
                })
                .catch((error) => {
                    console.error(error);
                    throw new Error('Error request');
                })
        },
        sendQuestionnaireToUser(context, payload = { user_id: null }) {
            return axios.post(`/admin/api/verification/ask-user-for-details?user_id=${payload.user_id}`)
                .then(r => {
                    return r.data;
                })
                .catch((error) => {
                    console.error(error);
                    throw new Error('Error request');
                })
        },
        sendVerificationLinkToUser(context, payload = { user_id: null }) {
            return axios.post(`/admin/api/verification/send-link-to-verification-and-reset-attempts?user_id=${payload.user_id}`)
                .then(r => {
                    return r.data;
                })
                .catch((error) => {
                    console.error(error);
                    throw new Error('Error request');
                })
        },
        getStatus2FA(context, id = null) {
            // Do get data
            return axios.get(`/admin/api/user/twofa-attempts?user_id=${id}`)
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        context.commit('SET_STATUS_2FA', data.data);
                    }

                    return data;
                })
                .catch((error) => {
                    console.log(error);
                    throw new Error('Error request');
                });
        },
        resetStatus2FA(context, id = null) {
            // Do send data
            return axios.post(`/admin/api/user/reset-twofa-attempts?user_id=${id}`)
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                    throw new Error('Error request');
                });
        },
        updateSecuritySettings(context, payload = {}) {
            const inputs = Object.assign({}, payload);
            delete inputs.user_id;

            // Do send data
            return axios.post(`/admin/api/user/update-security-settings?user_id=${payload.user_id}`, inputs)
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                    throw new Error('Error request');
                });
        },
        loadUserComments(context, params = {}) {
            // Do get data
            axios.get('/admin/api/user/comments', { params })
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        context.commit('SET_COMMENTS', data.data);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    throw new Error('Error request');
                });
        },
        createComment(context, payload = { user_id: null, text: '' }) {
            return axios.post(`/admin/api/user/add-comment?user_id=${payload.user_id}`, {
                text: payload.text
            })
                .then(async (response) => {
                    const data = response.data;

                    if (data.status) {
                        context.commit('ADD_COMMENT', data.data);
                    }

                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                    throw new Error('Error request');
                });
        },
        deleteComment(context, payload = { comment_id: null }) {
            return axios.post(`/admin/api/user/delete-comment?comment_id=${payload.comment_id}`)
                .then(async (r) => {
                    return r.data;
                })
                .catch((error) => {
                    console.log(error);
                    throw new Error('Error request');
                });
        },
        // createEditedComment(context, payload = { user_id: null, comment_id: null, text: '' }) {
        //     return axios.post(`/admin/api/user/add-comment?user_id=${payload.user_id}`, {
        //         text: `[edited ${payload.comment_id}] ${payload.text}`
        //     })
        //         .then(async (response) => {
        //             const data = response.data;
        //             if (data.status) {
        //                 context.commit('ADD_COMMENT', data.data);
        //             }
        //             return response.data;
        //         })
        //         .catch((error) => {
        //             console.log(error);
        //             throw new Error('Error request');
        //         });
        // },

        uploadAttachment(context, payload) {
            return axios.post(`/admin/api/user/upload-attachment`, payload, { asFormData: true })
                .then(async (response) => {
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                    throw new Error('Error request');
                });
        },
        updateAttachment(context, payload = { attachment_id: null, comment_id: null }) {
            return axios.post(`/admin/api/user/update-attachment?id=${payload.attachment_id}`, { comment_id: payload.comment_id })
                .then(async (response) => {
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                    throw new Error('Error request');
                });
        },

        loadUserReferrals(context, params = {}) {
            // Do get data
            axios.get(`/admin/api/user/referrals?parent_id=${params.parent_id}`, { params })
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        context.commit('SET_REFERRALS', data.data);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    throw new Error('Error request');
                });
        },
        deleteUserAccount(context, id = null) {
            // Do send data
            return axios.post(`/admin/api/user/delete-user?id=${id}`)
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                    throw new Error('Error request');
                });
        },

        /**
         * user_id
         * external_user_id=
         *
         * @param context
         * @param params
         * @returns {*}
         */
        reloadUserVerificationDocuments(context, params = {}) {
            const queryString = buildQueryString(params);

            // Do get data
            return axios.get(`/v2/pub/get-photo?${queryString}`)
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    console.log('Reload documents data error', error.data);
                });
        },

        setTotalBalance(context, data) {
            context.commit('SET_TOTAL_BALANCE', data);
        },

        loadUserHoldsList(context, params = {}) {
            // Do get data
            return axios.get('/admin/api/user/holds', {params}) // user_id, page
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        context.commit('SET_USER_HOLDS_LIST', data.data.holds);
                    }

                    return data;
                })
                .catch((error) => {
                    console.log(error);
                    throw new Error('Error request');
                });
        },
        loadUserPermissions(context) {
            // Do get data
            return axios.get('/admin/api/user/my-permissions')
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        context.commit('SET_USER_ROLE', data.data);
                    }

                    return data;
                })
                .catch((error) => {
                    console.log('Error request', error);
                });
        },
        /**
         * Operations EUR report. Send filter data
         *
         * @param context
         * @param params
         * @returns {*}
         */
        exportOperationsEURPOST(context, params = {}) {
            // Do get data
            return axios.post('/admin/api/user/operations-history-euro-export', params)
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                    throw new Error('Error request');
                });
        },
        /**
         * Operations EUR report. Get result data
         *
         * @param context
         * @param params
         * @returns {*}
         */
        exportOperationsEURGET(context, params = {}) {
            // Do get data
            return axios.get('/admin/api/user/operations-history-euro-export', {
                params
            })
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                    throw new Error('Error request');
                });
        },
        /**
         * Customers report. Send filter data
         *
         * @param context
         * @param params
         * @returns {*}
         */
        exportCustomersPOST(context, params = {}) {
            return axios.post('/admin/api/user/customers-history-euro-export', params)
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                    throw new Error('Error request');
                });
        },
        /**
         * Customers report. Get result data
         *
         * @param context
         * @param params
         * @returns {*}
         */
        exportCustomersGET(context, params = {}) {
            return axios.get('/admin/api/user/customers-history-euro-export', {
                params
            })
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                    throw new Error('Error request');
                });
        },

        /**
         * Run Export User detail data
         *
         * @param context
         * @param params
         * @returns {*}
         */
        exportUserDataPOST(context, params = {}) {
            // Do get data
            return axios.get('admin/api/user/detail-export', { params })
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    return error.data;
                });
        },

        /**
         * Get report export user detail
         *
         * @param context
         * @param params
         * @returns {*}
         */
        exportUserDataGET(context, params = {}) {
            // Do get data
            return axios.get('admin/api/user/detail-export', { params })
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    return error.data;
                });
        },

        /**
         * Run Bulk User detail export
         *
         * @param context
         * @param params
         * @returns {*}
         */
        exportUserDataBulkPOST(context, params = {}) {
            // Do get data
            return axios.post('admin/api/user/bulk-detail-export', params)
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    return error.data;
                });
        },

        /**
         * Get Bulk User detail export
         *
         * @param context
         * @param params
         * @returns {*}
         */
        exportUserDataBulkGET(context, params = {}) {
            // Do get data
            return axios.get('admin/api/user/bulk-detail-export', { params })
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    return error.data;
                });
        },

        loadUserRolesList(context) {
            // Do get data
            return axios.get('/admin/api/user/roles')
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        context.commit('SET_USER_ROLES_LIST', data.data.roles);
                    }

                    return data;
                })
                .catch((error) => {
                    console.log(error);
                    throw new Error('Error request');
                });
        },
        updateUserRole(context, payload = {}) {
            const data = Object.assign({}, payload);
            delete data.user_id;

            // Do get data
            return axios.post(`/admin/api/user/update?id=${payload.user_id}`, data)
                .then((response) => {
                    const data = response.data;

                    return data;
                })
                .catch((error) => {
                    console.log(error);
                    throw new Error('Error request');
                });
        },

        /**
         *
         * @param context
         * @param params
         * @returns {*}
         */
        exportUserBalance(context, currency = {}) {
            return axios.post(`/admin/api/user/balances-export?currency_id[]=${currency}`)
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    },
    modules: {}
};
