import axios from '../../utils/api';

export default {
    state: {
        contents: {
            list: [],
            pagination: {
                page: 0,
                totalCountPage: 0
            },
            total_count: 0,
        },
        categories: {
            list: []
        },
        subCategories: {
            list: []
        },
        contentsIsLoading: false,
        disclaimer: {
            text: null,
            translations: null
        },
    },
    getters: {
        getContentsList: (state) => state.contents,
        getContentsCategories: (state) => state.categories,
        getContentsSubCategories: (state) => state.subCategories,
        getContentsDisclaimer: (state) => state.disclaimer,
    },
    mutations: {
        SET_CONTENTS_LIST(state, data) {
            state.contents = data.news;
        },
        SET_CONTENTS_LOADING(state, data) {
            state.contentsIsLoading = data;

            if(data){
                state.contents.total_count = 0
            }
        },
        SET_CONTENTS_DISCLAIMER_TEXT(state, data) {
            state.disclaimer = data;
        },
        SET_CATEGORIES_LIST(state, data) {
            state.categories = data;
        },
        SET_SUB_CATEGORIES_LIST(state, data) {
            state.subCategories = data;
        }
    },
    actions: {
        /**
         * /admin/api/news/list?News[]=
         * этот запрос отдаёт категории
         *
         *
         * @param context
         * @param params
         * @returns {*}
         */
        loadContentsList(context, params = {}) {
            context.commit('SET_CONTENTS_LOADING', true);

            // Do get data
            return axios.get('/admin/api/news/list', {params})
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        context.commit('SET_CONTENTS_LIST', data.data);
                        context.commit('SET_CONTENTS_LOADING', false);
                    }

                    return data;
                })
                .catch((r) => {
                    context.commit('SET_CONTENTS_LOADING', false);

                    console.error('Error request:', r);
                });
        },

        /**
         * /admin/api/news/create
         *
         * JSON
         * general_data: {
         * category_id, url,
         * }
         * content: {
         * language_iso2, text (max 1650000), title (max 200), short_text (max 1000), keywords (400)
         * }
         *
         * FORM DATA:
         * image
         *
         * @param context
         * @param payload
         * @returns {*}
         */
        createContent(context, payload = {}) {
            return axios.post('/admin/api/news/create', payload)
                .then(r => {
                    return r.data;
                })
                .catch((r) => {
                    console.error('Error request:', r);
                });
        },

        /**
         * /admin/api/news/update?id=&language_iso2=
         *
         * JSON
         * general_data: {
         * category_id, url,
         * }
         * content: {
         * language_iso2, text (max 1650000), title (max 200), short_text (max 1000), keywords (400)
         * }
         *
         * FORM DATA:
         * image
         * @param context
         * @param payload
         * @returns {*}
         */
        updateContent(context, payload = {}) {
            const inputs = Object.assign({}, payload);
            delete inputs.id;

            // Do sen data
            return axios.post(`/admin/api/news/update?id=${payload.id}&language_iso2=${payload.language}`, inputs)
                .then(r => {
                    return r.data;
                })
                .catch((r) => {
                    console.error('Error request:', r);
                });
        },

        /**
         * /admin/api/news/update?id=&language_iso2=
         *
         * FORM DATA:
         * image
         *
         * @param context
         * @param payload
         * @returns {*}
         */
        updateContentImage(context, payload = {}) {
            return axios.post(
                `/admin/api/news/update?id=${payload.id}&language_iso2=${payload.language}`,
                payload.data,
                {asFormData: true}
            )
                .then(r => {
                    return r.data;
                })
                .catch((r) => {
                    console.error('Error request:', r);
                });
        },

        /**
         * @param context
         * @param payload
         * @returns {*}
         */
        deleteContent(context, payload = {}){
            // Do sen data
            return axios.post(`/admin/api/news/delete`, payload)
                .then(r => {
                    return r.data;
                })
                .catch((r) => {
                    console.error('Error request:', r);
                });
        },

        /**
         * { "id": ID }
         *
         * @param context
         * @param payload
         * @returns {*}
         */
        archiveContent(context, payload = {}){
            return axios.post(`/admin/api/news/archive`, payload)
                .then(r => {
                    return r.data;
                })
                .catch((r) => {
                    console.error('Error request:', r);
                });
        },

        /**
         * { "id": ID }
         *
         * @param context
         * @param payload
         * @returns {*}
         */
        restoreContent(context, payload = {}){
            return axios.post(`/admin/api/news/restore`, payload)
                .then(r => {
                    return r.data;
                })
                .catch((r) => {
                    console.error('Error request:', r);
                });
        },

        /**
         * { "text": Disclaimer text }
         *
         * @param context
         * @param payload
         * @returns {*}
         */
        editDisclaimerText(context, payload = {}){
            return axios.post(`/admin/api/news/disclaimer`, payload)
                .then(r => {
                    return r.data;
                })
                .catch((r) => {
                    console.error('Error request:', r);
                });
        },

        /**
         * @returns {*}
         */
        loadDisclaimerText(context){
            return axios.get(`/admin/api/news/disclaimer`)
                .then(r => {
                    context.commit('SET_CONTENTS_DISCLAIMER_TEXT', r?.data?.data?.disclaimer ?? null);

                    return r.data;
                })
                .catch((r) => {
                    console.error('Error request:', r);
                });
        },

        /**
         * Get categories list
         *
         * @param context
         * @param params
         * @returns {*}
         */
        loadCategoriesList(context, params = {}) {
            return axios.get('/admin/api/news/categories', {params})
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        context.commit('SET_CATEGORIES_LIST', data.data);
                    }

                    return data;
                })
                .catch((r) => {
                    console.error('Error request:', r);
                });
        },

        /**
         * Create new category
         *
         * @param context
         * @param payload
         * @returns {*}
         */
        createContentsCategory(context, payload = {}) {
            return axios.post('/admin/api/news/category', payload)
                .then(r => {
                    return r.data;
                })
                .catch((r) => {
                    return r.data;
                });
        },

        /**
         * Update exist category
         *
         * @param context
         * @param payload
         * @returns {*}
         */
        updateContentsCategory(context, payload = {}) {
            return axios.patch('/admin/api/news/category', payload)
                .then(r => {
                    return r.data;
                })
                .catch((r) => {
                    return r.data;
                });
        },

        /**
         * Get categories list
         *
         * @param context
         * @param params
         * @returns {*}
         */
        loadSubCategoriesList(context, params = {}) {
            return axios.get('/admin/api/news/sub-categories', {params})
            .then((response) => {
                const data = response.data;

                if (data.status) {
                    context.commit('SET_SUB_CATEGORIES_LIST', data.data);
                }

                return data;
            })
            .catch((r) => {
                return r.data;
            });
        },

        /**
         * Create new category
         *
         * @param context
         * @param payload
         * @returns {*}
         */
        createContentsSubCategory(context, payload = {}) {
            return axios.post('/admin/api/news/sub-category', payload)
            .then(r => {
                return r.data;
            })
            .catch((r) => {
                return r.data;
            });
        },

        /**
         * Update exist category
         *
         * @param context
         * @param payload
         * @returns {*}
         */
        updateContentsSubCategory(context, payload = {}) {
            return axios.patch('/admin/api/news/sub-category', payload)
            .then(r => {
                return r.data;
            })
            .catch((r) => {
                return r.data;
            });
        },
    },
    modules: {}
};
