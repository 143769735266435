import {createStore} from 'vuex';

/**
 * Modules
 */
import configs from "@/store/modules/configs";
import users from "@/store/modules/users";
import session from "@/store/modules/session";
import transactions from "@/store/modules/transactions";
import affiliates from "@/store/modules/affiliates";
import dashboard from "@/store/modules/dashboard";
import content from "@/store/modules/content";
import systemWallets from "@/store/modules/systemWallets"
import userWallets from "@/store/modules/userWallets";
import coinOrders from "@/store/modules/coinOrders";
import trades from "@/store/modules/trades";
import currencies from "@/store/modules/currencies";
import bots from "@/store/modules/bots";
import rewardRanges from './modules/rewardRanges';
import bonusPrograms from "@/store/modules/bonusPrograms";
import leaderboard from "@/store/modules/leaderboard";
import launchpad from "@/store/modules/launchpad";
import emailTemplates from "@/store/modules/emailTemplates";
import roles from './modules/roles';

/**
 * Global Store
 */
export default createStore({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        configs,
        users,
        session,
        coinOrders,
        transactions,
        affiliates,
        dashboard,
        content,
        systemWallets,
        userWallets,
        trades,
        currencies,
        bots,
        rewardRanges,
        bonusPrograms,
        leaderboard,
        launchpad,
        emailTemplates,
        roles
    }
});
