import axios from '../../utils/api';

export default {
    state: {
        botDefaultSettings: {
            markup_ranges: {}
        },
        botPairSettings: {
            markup_ranges: {}
        },
    },
    getters: {
        getBotDefaultSettings: (state) => state.botDefaultSettings,
        getBotPairSettings: (state) => state.botPairSettings,
    },
    mutations: {
        SET_BOT_DEFAULT_SETTINGS(state, data) {
            state.botDefaultSettings = data
        },
        SET_BOT_PAIR_SETTINGS(state, data) {
            state.botPairSettings = data
        },
    },
    actions: {
        loadBotDefaultSettings(context, params = {}) {
            // Do get data
            return axios.get('/admin/api/currencies/bot-settings-get', {params})
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        context.commit('SET_BOT_DEFAULT_SETTINGS', data.data.settings);
                    }

                    return data;
                })
                .catch((error) => {
                    console.log(error);
                    throw new Error('Error request');
                });
        },
        loadBotPairSettings(context, params = {}) {
            // Do get data
            return axios.get(`/admin/api/currencies/bot-settings-get?currency_id=${params.currency_id}`)
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        context.commit('SET_BOT_PAIR_SETTINGS', data.data.settings);
                    }

                    return data;
                })
                .catch((error) => {
                    console.log(error);
                    throw new Error('Error request');
                });
        },
        updateBotDefaultSettings(context, data = {}) {
            // Update settings
            return axios.post('/admin/api/currencies/bot-settings-update', { markup_ranges: data })
                .then((response) => {
                    const data = response.data;

                    return data;
                })
                .catch((error) => {
                    console.log(error);
                    throw new Error('Error request');
                });
        },
        updateBotPairSettings(context, params = {}) {
            // Update settings
            return axios.post(`/admin/api/currencies/bot-settings-update?currency_id=${params.currency_id}`, params.payload)
                .then((response) => {
                    const data = response.data;

                    return data;
                })
                .catch((error) => {
                    console.log(error);
                    throw new Error('Error request');
                });
        },
    },
    modules: {}
};
