import axios from '../../utils/api';

export default {
    state: {
        /**
         * affiliates
         */
        affiliates: {
            list: [],
            pagination: {
                page: 0,
                totalCountPage: 0
            },
            total_count: 0,
        },
        affiliatesIsLoading: false,
        affiliateSelected: {},

        /**
         * Links
         */
        affiliateLinks: {
            list: [],
            pagination: {
                page: 0,
                totalCountPage: 0
            },
            total_count: 0
        },
        affiliateLinksIsLoading: false,
        affiliateLinkSelected: {},

        /**
         * conversions
         */
        conversions: {
            list: [],
            pagination: {
                page: 0,
                totalCountPage: 0
            },
            total_count: 0
        },
        conversionTypes: {
            1: 'Deposit',
            2: 'Registration',
            3: 'Trade',
            4: 'Withdraw'
        },
        conversionStatuses: {
            0: 'Created',
            1: 'REWARD PAY IN PROCESS',
            2: 'REWARD PAYED',
            3: 'REWARD ERROR',
            4: 'NO REWARD'
        },
        conversionsIsLoading: false,

        /**
         * Conversion Statistic
         */
        conversionsStatistic: [],

        /**
         * Rewards Settings
         */
        rewardsSettings: {
            list: [],
            pagination: {
                page: 0,
                totalCountPage: 0
            },
            total_count: 0
        }
    },
    getters: {
        /**
         * Affiliates
         *
         * @param state
         * @returns {{pagination: {totalCountPage: number, page: number}, total_count: number, list: []}}
         */
        getAffiliatesList: (state) => state.affiliates,
        getAffiliatesListCount: (state) => state.affiliates.total_count,
        getAffiliateSelected: (state) => state.affiliateSelected,
        getAffiliatesIsLoading: (state) => state.affiliatesIsLoading,

        /**
         * Affiliate Links
         *
         * @param state
         * @returns {{pagination: {totalCountPage: number, page: number}, total_count: number, list: []}}
         */
        getAffiliateLinksList: (state) => state.affiliateLinks,
        getAffiliateLinksListCount: (state) => state.affiliateLinks.total_count,
        getAffiliateLinkSelected: (state) => state.affiliateLinkSelected,
        getAffiliateLinksIsLoading: (state) => state.affiliateLinksIsLoading,

        /**
         * Link Conversions
         *
         * @param state
         * @returns {{pagination: {totalCountPage: number, page: number}, total_count: number, list: []}}
         */
        getConversionsList: (state) => state.conversions,
        getConversionsListCount: (state) => state.conversions.total_count,
        getConversionsIsLoading: (state) => state.conversionsIsLoading,
        getConversionTypes: (state) => state.conversionTypes,
        getConversionStatuses: (state) => state.conversionStatuses,

        /**
         * Conversion statistic
         *
         * @param state
         * @returns {[]}
         */
        getConversionStatistics: (state) => state.conversionsStatistic,
    },
    mutations: {
        /**
         * Affiliates
         */
        SET_AFFILIATES_LIST(state, data) {
            state.affiliates = data;
        },
        SET_AFFILIATES_LOADING(state, data) {
            state.affiliatesIsLoading = data;

            if(data){
                // state.affiliates = {
                //     list: [],
                //     pagination: {
                //         page: 0,
                //         totalCountPage: 0
                //     },
                //     total_count: 0
                // }

                state.affiliates.total_count = 0
            }
        },
        SET_AFFILIATE_SELECTED(state, data) {
            state.affiliateSelected = data;
        },

        /**
         * Affiliate Links
         */
        SET_AFFILIATE_LINKS_LIST(state, data) {
            state.affiliateLinks = data;
        },
        SET_AFFILIATE_LINKS_LOADING(state, data) {
            state.affiliateLinksIsLoading = data;

            if(data){
                // state.affiliateLinks = {
                //     list: [],
                //         pagination: {
                //         page: 0,
                //             totalCountPage: 0
                //     },
                //     total_count: 0
                // }

                state.affiliateLinks.total_count = 0
            }
        },
        SET_AFFILIATE_LINK_SELECTED(state, data) {
            state.affiliateLinkSelected = data;
        },

        /**
         * Conversions
         */
        SET_CONVERSIONS_LIST(state, data) {
            state.conversions = data;
        },
        SET_CONVERSIONS_LOADING(state, data) {
            state.conversionsIsLoading = data;

            if(data){
                // state.conversions = {
                //     list: [],
                //     pagination: {
                //         page: 0,
                //         totalCountPage: 0
                //     },
                //     total_count: 0
                // }

                state.conversions.total_count = 0
            }
        },

        SET_CONVERSIONS_STATISTIC(state, data) {
            state.conversionsStatistic = data;
        },
        SET_REWARDS_SETTINGS_LIST(state, data) {
            state.rewardsSettings = data;
        },
    },
    actions: {
        /**
         * @param context
         * @param params /*{user_id, user_search, source}
         * @returns {*}
         */
        loadAffiliatesList(context, params = {}) {
            context.commit('SET_AFFILIATES_LOADING', true);

            // Do get data
            return axios.get('/admin/api/affiliate/list', {params})
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        context.commit('SET_AFFILIATES_LIST', data.data);
                        context.commit('SET_AFFILIATES_LOADING', false);
                    }

                    return data;
                })
                .catch((error) => {
                    console.log(error);

                    context.commit('SET_AFFILIATES_LOADING', false);

                    throw new Error('Error request');
                });
        },
        getAffiliateByUserId(context, id = null) {
            // Do get data
            return axios.get(`/admin/api/user/affiliates?user_id=${id}`)
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        context.commit('SET_AFFILIATE_SELECTED', data.data.list[0]);

                        return data.data.list[0];
                    }

                    return null;
                })
                .catch((error) => {
                    console.log(error);
                    throw new Error('Error request');
                });
        },
        setSelectedAffiliate(context, item = {}){
            context.commit('SET_AFFILIATE_SELECTED', item);
        },

        /**
         * @param context
         * @param params
         * @returns {*}
         */
        loadAffiliateLinksList(context, params = {}) {
            context.commit('SET_AFFILIATE_LINKS_LOADING', true);

            // Do get data
            return axios.get('/admin/api/affiliate/list-links', {params})
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        context.commit('SET_AFFILIATE_LINKS_LIST', data.data);
                        context.commit('SET_AFFILIATE_LINKS_LOADING', false);
                    }

                    return data;
                })
                .catch((error) => {
                    console.log(error);

                    context.commit('SET_AFFILIATE_LINKS_LOADING', false);

                    throw new Error('Error request');
                });
        },

        /**
         * Creating affiliate link
         *
         * @param context
         * @param payload
         * - source: "local",
         * - name: "Test YouTube", // туда можно передать опционально name — имя ссылки
         * - user_id: 1111111
         *
         * @returns {*}
         */
        createAffiliateLink(context, payload = {}){
            // Do sen data
            return axios.post('/admin/api/affiliate/create-affiliate-link', payload)
                .then(r => {
                    return r.data;
                })
                .catch((r) => {
                    console.error(r);
                    throw new Error('Error request');
                })
        },
        setSelectedAffiliateLink(context, item = {}){
            context.commit('SET_AFFILIATE_LINK_SELECTED', item);
        },

        /**
         * Get Conversions list
         *
         * @param context
         * @param params
         *  - user_id
         *  - time_create_from (ISO)
         *  - time_create_to (ISO)
         *  - link — (string with link from prev request)
         *  - type
         *  - customer_id
         *  - currency_id
         * @returns {*}
         */
        loadConversionsList(context, params = {}) {
            context.commit('SET_CONVERSIONS_LOADING', true);

            // Do get data
            return axios.get('/admin/api/affiliate/list-conversions', {params})
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        context.commit('SET_CONVERSIONS_LIST', data.data);
                        context.commit('SET_CONVERSIONS_LOADING', false);
                    }

                    return data;
                })
                .catch((error) => {
                    console.log(error);

                    context.commit('SET_CONVERSIONS_LOADING', false);

                    throw new Error('Error request');
                });
        },
        loadStatisticConversionsList(context, params = {}) {
            // Do get data
            return axios.get('/admin/api/affiliate/statistic-conversions', {params})
                 .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        context.commit('SET_CONVERSIONS_STATISTIC', data.data);
                    }

                    return data;
                })
                .catch((error) => {
                    console.log(error);
                    throw new Error('Error request');
                });
        },

        /**
         * Get Rewards Settings
         *
         * @param context
         * @param payload
         * @returns {*}
         */
        loadRewardsSettings(context, payload = {}) {
            // [user_id]
            // [affiliate_id}
            // [user_search}

            const params = {
                sort: '- time_create'
            }
            if (Object.keys(payload).length > 0) {
                params.AffiliatesRewardsSettings = {};

                for (let key in payload) {
                    if (payload[key] !== '') {
                        params.AffiliatesRewardsSettings[key] = payload[key];
                    }
                }
            }

            // Do get data
            return axios.get('/admin/api/affiliate/list-rewards-settings', {params})
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        context.commit('SET_REWARDS_SETTINGS_LIST', data.data);
                    }

                    return data.data;
                })
                .catch((error) => {
                    console.log(error);
                    throw new Error('Error request');
                });
        },

        /**
         * Rewards Settings
         *
         * @param context
         * @param payload
         * @returns {*}
         */
        setRewardsSettingsByAffiliate(context, payload = {}){
            const inputs = Object.assign({}, payload);

            let getParams = '';
            if(inputs.id){
                getParams = `?id=${inputs.id}`;

                delete inputs.id;
            }

            // Do send data
            return axios.post(`/admin/api/affiliate/set-rewards-setting${getParams}`, inputs)
                .then(r => {
                    return r.data;
                })
                .catch((r) => {
                    console.error(r);
                    throw new Error('Error request');
                });
        },
        setRewardsSettingsForAll(context, payload = {}){
            // Do sen data
            return axios.post('/admin/api/affiliate/set-rewards-settings-for-all-affiliates', payload)
                .then(r => {
                    return r.data;
                })
                .catch((r) => {
                    console.error(r);
                    throw new Error('Error request');
                });
        },

        /**
         * Delete Rewards Settings By ID
         *
         * @param context
         * @param id
         * @returns {*}
         */
        deleteRewardsSettings(context, id = null) {
            const params = {
                id
            }

            // Do sen data
            return axios.post('/admin/api/affiliate/delete-rewards-setting', params)
                .then(r => {
                    return r.data;
                })
                .catch((r) => {
                    console.error(r);
                    throw new Error('Error request');
                });
        },
    },
    modules: {}
};
