import axios from '../../utils/api';

export default {
    state: {
        rewardRanges: [],
    },
    getters: {
        getRewardRanges: state => state.rewardRanges,
    },
    mutations: {
        SET_REWARD_RANGES(state, data) {
            state.rewardRanges = data;
        },
    },
    actions: {
        loadRewardRanges(context, params = {}) {
            // Do get data
            return axios
                .get('/admin/api/ref-service/api/reward-ranges', { params })
                .then(response => {
                    const data = response.data.data;
                    if (!data.status) {
                        if (data.reward_ranges?.length === 0) {
                            throw new Error('No data available');
                        } else {
                            const rewardRanges = [];
                            data.reward_ranges.forEach(element => {
                                rewardRanges.push(...element.ranges.map(item => ({ level: element.level, ...item })));
                            });
                            context.commit('SET_REWARD_RANGES', rewardRanges);
                        }
                    } else {
                        throw new Error('No data available');
                    }
                })
                .catch(error => {
                    throw error;
                });
        },
        updateRewardRanges(context, data = {}) {
            const sendData = [];
            const levels = Array.from(new Set(data.map(item => item.level))).filter(elem => elem !== undefined);
            levels.forEach(level => {
                const levelRanges = data.filter(elem => level === elem.level);
                sendData.push({
                    level,
                    ranges: [...levelRanges.map(item => ({ from: item.from, to: item.to, procReward: item.procReward }))],
                });
            });
            // Update settings
            context.commit('SET_REWARD_RANGES', data);
            return axios
                .post('/admin/api/ref-service/api/reward-ranges', { reward_ranges: sendData })
                .then(response => {
                    if (!response.data.status) throw new Error(response.data.data.message);
                })
                .catch(error => {
                    throw error;
                });
        },
    },
    modules: {},
};

