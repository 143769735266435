import axios from '../../utils/api';
import {buildQueryString} from "@/utils/BuildQueryString";

export default {
    state: {
        /**
         * Wallets Statistics
         */
        wallets: {
            list: [],
            total_by_provider_usdt: {},
            total_usdt: {},
            reBalancingSettings: {},
            network_type_to_native_currency_id: {},
            pagination: {
                page: 0,
                totalCountPage: 0
            },
            total_count: 0
        },
        walletsIsLoading: {
            type: Boolean,
            default: false,
        },
        reBalancingSettingsIsLoading: false,

        /**
         * Transactions
         */
        transactions: {
            filters: {},
            list: [],
            pagination: {
                page: 0,
                totalCountPage: 0
            },
            total_count: 0
        },
        transactionsIsLoading: false,

        /**
         * Selected address
         */
        selectedAddress: ''
    },
    getters: {
        getSystemWalletsStatisticsList: (state) => state.wallets.list,
        getSystemWalletsStatisticsTotalUSDT: (state) => state.wallets.total_usdt,
        getSystemWalletsStatisticsTotalByProviderUsdt: (state) => state.wallets.total_by_provider_usdt,
        getSystemWalletsStatisticsPagination: (state) => state.wallets.pagination,
        getSystemWalletsStatisticsTotalCount: (state) => state.wallets.total_count,
        getSystemWalletsRebalancingSettings: (state) => state.wallets.reBalancingSettings,
        getSystemWalletsRebalancingNetworkTypeToNativeCurrencyId: (state) => state.wallets.network_type_to_native_currency_id,
        getSystemWalletsLiquidityProviders: (state) => state.liquidityProviders,
        getSystemWalletsStatisticsLoading: (state) => state.walletsIsLoading,

        getSystemWalletsTransactions: (state) => state.transactions,
        getSystemTransactionsFiltersType: (state) => state.transactions.filters.type,
        getSelectedAddress:  (state) => state.selectedAddress,
    },
    mutations: {
        SET_SYSTEM_WALLETS_STATISTIC(state, data) {
            state.wallets.list = data.list;
            state.wallets.total_by_provider_usdt = data.total_by_provider_usdt;
            state.wallets.total_usdt = data.total_usdt;

            if(data.pagination !== false){
                state.wallets.pagination = data.pagination;
            }else{
                state.wallets.pagination = {
                    page: 0,
                    totalCountPage: 0
                };
            }

            state.wallets.total_count = data.total_count;
        },
        SET_SYSTEM_WALLETS_STATISTIC_LOADING(state, data) {
            state.walletsIsLoading = data;
        },
        SET_SYSTEM_WALLETS_REBALANCING_NETWORK_TYPE_TO_NATIVE_CURRENCY_ID(state, data) {
            state.wallets.network_type_to_native_currency_id = data;
        },
        SET_SYSTEM_WALLETS_REBALANCING_SETTINGS(state, data) {
            state.wallets.reBalancingSettings = data;
        },
        SET_SYSTEM_WALLETS_REBALANCING_SETTINGS_LOADING(state, data) {
            state.reBalancingSettingsIsLoading = data;
        },
        SET_SYSTEM_WALLETS_LIQUIDITY_PROVIDERS(state, data) {
            state.liquidityProviders = data;
        },
        SET_SYSTEM_WALLETS_TRANSACTIONS_LIST(state, data) {
            state.transactions = data;
        },
        SET_SYSTEM_WALLETS_TRANSACTIONS_LOADING(state, data) {
            state.transactionsIsLoading = data;
        },
        SET_ADDRESS_SELECTED(state, data) {
            state.selectedAddress = data;
        },
    },
    actions: {
        /**
         * ?user_id=
         * &currency_id=
         * &network_type=
         * &txid=
         * &status=
         * &page=
         *
         * и time_create= в формате “ISO_FROM - ISO_TO“
         *
         * @param context
         * @param params
         * @returns {*}
         */
        loadSystemWalletsTransactionsList(context, params = {}) {
            context.commit('SET_SYSTEM_WALLETS_TRANSACTIONS_LOADING', true);

            // Do get data
            return axios.get('/admin/api/system-wallets/transactions', {params})
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        context.commit('SET_SYSTEM_WALLETS_TRANSACTIONS_LIST', data.data.transactions);
                        context.commit('SET_SYSTEM_WALLETS_TRANSACTIONS_LOADING', false);
                    }

                    return data;
                })
                .catch((error) => {
                    console.log(error);

                    context.commit('SET_SYSTEM_WALLETS_TRANSACTIONS_LOADING', false);

                    throw new Error('Error request');
                });
        },

        /**
         * @method GET
         * @param context
         * @param payload
         * @returns {*}
         */
        loadSystemWalletsTotalStatistic(context, payload = {}) {
            context.commit('SET_SYSTEM_WALLETS_STATISTIC_LOADING', true);

            const queryString = buildQueryString(payload);

            // Do get data
            return axios.get(`/admin/api/system-wallets/total-wallets-statistic?${queryString}`)
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        context.commit('SET_SYSTEM_WALLETS_STATISTIC', data.data);
                        context.commit('SET_SYSTEM_WALLETS_STATISTIC_LOADING', false);
                    }

                    return data;
                })
                .catch((error) => {
                    console.log(error);

                    context.commit('SET_SYSTEM_WALLETS_STATISTIC_LOADING', true);

                    throw new Error('Error request');
                });
        },

        loadSystemWalletsLiquidityProviders(context) {
            // Do get data
            return axios.get('/admin/api/system-wallets/liquidity-providers')
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        context.commit('SET_SYSTEM_WALLETS_LIQUIDITY_PROVIDERS', data.data.list);
                    }

                    return data;
                })
                .catch((error) => {
                    console.log(error);

                    throw new Error('Error request');
                });
        },

        loadSystemWalletsLiquidityProvidersLiqpaid(context) {
            // Do get data
            return axios.get('/admin/api/system-wallets/liq-providers')
            .then((response) => {
                const data = response.data;

                if (data.status) {
                    context.commit('SET_SYSTEM_WALLETS_LIQUIDITY_PROVIDERS', data.data.list);
                }

                return data;
            })
            .catch((error) => {
                console.log(error);

                throw new Error('Error request');
            });
        },

        loadSystemWalletsReBalancingSettings(context) {
            context.commit('SET_SYSTEM_WALLETS_REBALANCING_SETTINGS_LOADING', true);

            // Do get data
            return axios.get('/admin/api/system-wallets/rebalancing-settings')
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        context.commit('SET_SYSTEM_WALLETS_REBALANCING_SETTINGS', data.data.settings);
                        context.commit('SET_SYSTEM_WALLETS_REBALANCING_SETTINGS_LOADING', false);
                        context.commit('SET_SYSTEM_WALLETS_REBALANCING_NETWORK_TYPE_TO_NATIVE_CURRENCY_ID', data.data.network_type_to_native_currency_id);
                    }

                    return data;
                })
                .catch((error) => {
                    console.log(error);

                    context.commit('SET_SYSTEM_WALLETS_REBALANCING_SETTINGS_LOADING', true);

                    throw new Error('Error request');
                });
        },

        /**
         * Save rebalancing settings
         *
         * @param context
         * @param payload
         * @returns {*}
         */
        saveRebalancingSettings(context, payload = {}){
            // Do sen data
            return axios.post('/admin/api/system-wallets/rebalancing-settings', payload)
                .then(r => {
                    return r.data;
                })
                .catch((r) => {
                    console.error(r);
                    throw new Error('Error request');
                })
        },

        setSelectedAddress(context, item = '') {
            context.commit('SET_ADDRESS_SELECTED', item);
        },

        exportSystemWalletsTransactionsHistoryPOST(context, params = {}) {
            // Do get data
            return axios.post('/admin/api/system-wallets/transactions-export', params)
                .then((response) => {
                    const data = response.data;
                    return data;
                })
                .catch((error) => {
                    console.log(error);
                    throw new Error('Error request');
                });
        },
        exportSystemWalletsTransactionsHistoryGET(context, params = {}) {
            // Do get data
            return axios.get('/admin/api/system-wallets/transactions-export', { params })
                .then((response) => {
                    const data = response.data;
                    return data;
                })
                .catch((error) => {
                    console.log(error);
                    throw new Error('Error request');
                });
        },

        doTransferToLP(context, payload = {}){
            // Do sen data
            return axios.post('/admin/api/system-wallets/rebalance-to-liq-provider', payload)
            .then(r => {
                return r.data;
            })
            .catch((r) => {
                return r.data;
            })
        },
    },
    modules: {}
};
