import axios from '../../utils/api';

export default {
    state: {
        emailTemplates: {
            list: [],
            pagination: {
                page: 0,
                totalCountPage: 0
            },
            total_count: 0
        },
        events: [
            {
                key: 'aml_reject',
                label: 'Aml Reject'
            },
            {
                key: 'aml_reject_admin',
                label: 'Aml Reject Admin'
            },
            {
                key: 'account_is_unblocked',
                label: 'Account Is Unblocked'
            },
            {
                key: 'activation',
                label: 'Account Activation'
            },
            {
                key: 'change_password',
                label: 'Change Password'
            },
            {
                key: 'code_send',
                label: 'Code Send'
            },
            {
                key: 'deposit_confirmed',
                label: 'Deposit Confirmed'
            },
            {
                key: 'deposit_for_admin',
                label: 'Deposit For Admin'
            },
            {
                key: 'device_confirm',
                label: 'Device Confirm'
            },
            {
                key: 'frozen_assets',
                label: 'Frozen Assets'
            },
            {
                key: 'invoice_transaction_overpaid',
                label: 'Invoice Transaction Overpaid'
            },
            {
                key: 'invoice_transaction_underpayed',
                label: 'Invoice Transaction Underpaid'
            },
            {
                key: 'link_send',
                label: 'Link Send'
            },
            {
                key: 'login',
                label: 'Login'
            },
            {
                key: 'login_confirm',
                label: 'Login Confirm'
            },
            {
                key: 'message',
                label: 'Message'
            },
            {
                key: 'message_verify_waiting',
                label: 'Message Verify Waiting'
            },
            {
                key: 'questionnaire_approved',
                label: 'Questionnaire Approved'
            },
            {
                key: 'questionnaire_link',
                label: 'Questionnaire Link'
            },
            {
                key: 'questionnaire_under_review',
                label: 'Questionnaire Under Review'
            },
            {
                key: 'questionnaire_under_review_for_admin',
                label: 'Questionnaire Under Review For Admin'
            },
            {
                key: 'recovery',
                label: 'Recovery'
            },
            {
                key: 'security_setting_update',
                label: 'Security Setting Update'
            },
            {
                key: 'trades_volume_for_admin',
                label: 'Trades Volume For Admin'
            },
            {
                key: 'transfer_internal_success_receiver',
                label: 'Transfer Internal Success Receiver'
            },
            {
                key: 'transfer_internal_success_sender',
                label: 'Transfer Internal Success Sender'
            },
            {
                key: 'verification_rejected',
                label: 'Verification Rejected'
            },
            {
                key: 'verification_send_link',
                label: 'Verification Send Link'
            },
            {
                key: 'verification_success',
                label: 'Verification Success Completed'
            },
            {
                key: 'withdraw_cancel',
                label: 'Withdraw Cancel'
            },
            {
                key: 'withdraw_confirm',
                label: 'Withdraw Confirmation'
            },
            {
                key: 'withdraw_success',
                label: 'Withdraw Success'
            }
        ]
    },
    getters: {
        getEmailTemplatesData: (state) => state.emailTemplates,
        getEmailEventsList: (state) => state.events,
    },
    mutations: {
        SET_EMAIL_TEMPLATES_LIST(state, data) {
            state.emailTemplates = data;
        },
    },
    actions: {
        /**
         * Load launchpad projects list
         *
         * @param context
         * @param params
         * @returns {*}
         */
        loadEmailTemplateList(context, params = {}) {
            // Do get data
            return axios.get('/admin/api/mail-template/list', {params})
                .then((response) => {
                    const data = response.data;

                    if (data.data) {
                        context.commit('SET_EMAIL_TEMPLATES_LIST', data.data);
                    }

                    return data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        /**
         * @param context
         * @param params
         * @returns {*}
         */
        createEmailTemplate(context, params = {}) {
            // Do get data
            return axios.post(`/admin/api/mail-template/create`, params)
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    return error.data;
                });
        },

        /**
         * @param context
         * @param params
         * @returns {*}
         */
        updateEmailTemplate(context, params = {}) {
            // Do get data
            return axios.post(`/admin/api/mail-template/update?id=${params.id}&language_iso2=${params.language}`, params.data)
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    return error.data;
                });
        },

        /**
         * /admin/api/news/update?id=&language_iso2=
         *
         * FORM DATA:
         * image
         *
         * @param context
         * @param payload
         * @returns {*}
         */
        updateEmailTemplateImage(context, payload = {}) {
            return axios.post(
                `/admin/api/mail-template/update?id=${payload.id}&language_iso2=${payload.language}`,
                payload.data,
                {asFormData: true}
            )
            .then(r => {
                return r.data;
            })
            .catch((r) => {
                console.error('Error request:', r);
            });
        },
    },
    modules: {}
};
