const from_int = 10 ** 8;

export function formatNumCurrency(number, decimal = 5, zeroCut = 2) {
    const numberParts = number.toString().split('.');

    const floatingValue = (numberParts[1] || '').replace(/0+$/, '').slice(0, decimal);

    return floatingValue ? `${numberParts[0]}.${floatingValue}` : numberParts[0] + (zeroCut > 0 ? '.' + '0'.repeat(zeroCut) : '');
}

/**
 * Format Exponential Number
 *
 * @param input
 * @returns {*|string}
 */
export function formatExponentialNumber(input) {
    return input.noExponents()
}

Number.prototype.noExponents = function () {
    const data = String(this).split(/[eE]/);
    if (data.length === 1) return data[0];

    let z = '',
        sign = this < 0 ? '-' : '',
        str = data[0].replace('.', ''),
        mag = Number(data[1]) + 1;

    if (mag < 0) {
        z = sign + '0.';
        while (mag++) z += '0';
        return z + str.replace(/^-/, '');
    }

    mag -= str.length;
    while (mag--) z += '0';

    return str + z;
}

/**
 * Split number by digit group
 */
export function numberDelimiter(number, group = 3) {
    const parts = String(number).replace(/\s/g, '').trim().split('.');

    const integerValue = parts[0]
        .split("")
        .reverse()
        .map((symbol, index) => {
            if (index % group === 0 && index !== 0) {
                return symbol + ' ';
            } else {
                return symbol;
            }
        })
        .reverse()
        .join("");

    return integerValue + (parts[1] ? '.' + parts[1] : '');
}

export function decimalFromInt(_num) {
    let num = typeof _num === 'number' ? +_num / from_int : 0;

    if (Math.abs(num) < 1.0) {
        return num.toFixed(9);
    } else {
        return formatExponentialNumber(num)
    }
}

export const formattedNumFromInt = (value, decimals = 5, cut = 2) => formatNumCurrency(decimalFromInt(value), decimals, cut);

//export function numberFormat(amount, iso){
//    return numberDelimiter(formatNumCurrency(decimalFromInt(amount), iso === 'USDT' ? 2 : 8, iso === 'USDT' ? 2 : 8));
//           numberDelimiter(formatNumCurrency(decimalFromInt(v.balance_available), iso === 'USDT' ? 2 : 8, iso === 'USDT' ? 2 : 8)),
//}

/**
 * Format display date
 *
 * @param time
 * @param useUTC
 * @returns {string}
 */
export function formatDate(time, useUTC = true) {
    let date;

    // Check, if time is string and has 'T', then it's ISO 8601 format
    if (typeof time === 'string' && time.includes('T')) {
        date = new Date(time);
    } else {
        date = new Date(time * 1000); // Предполагаем, что передано Unix Timestamp
    }

    const toFormattedString = (date) => {
        return String(date.getDate()).padStart(2, '0') + "/" +
            String(date.getMonth() + 1).padStart(2, '0') + "/" +
            date.getFullYear() + " " +
            String(date.getHours()).padStart(2, '0') + ":" +
            String(date.getMinutes()).padStart(2, '0') + ":" +
            String(date.getSeconds()).padStart(2, '0');
    }

    if (useUTC) {
        const utcDate = new Date(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes(),
            date.getUTCSeconds()
        );

        return toFormattedString(utcDate);
    } else {
        return toFormattedString(date);
    }
}

/**
 * Local ISO String
 *
 * @returns {string}
 * @param date
 */
export function getLocalISOString(date) {
    const localOffset = date.getTimezoneOffset() * 60000; // Convert minutes to milliseconds
    const localTime = new Date(date.getTime() - localOffset);
    return localTime.toISOString();
}

/**
 * Fixing decimal number
 *
 * @param num
 * @param fixed
 * @returns {string}
 */
export function toFixed(num, fixed) {
    if (!num) {
        return '0';
    }

    const re = new RegExp(`^-?\\d+(?:.\\d{0,${fixed || -1}})?`);

    return String(num).match(re)[0].replace(/\.?0+$/, '');
}
