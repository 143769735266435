import axios from '../../utils/api';

export default {
  state: {
    roles: {
      list: [],
      page: 0,
      total_pages: 0
    },

    roleStatuses: {
      0: 'Enabled',
      1: 'Disabled'
    },

    rolePermissions: []
  },
  getters: {
    getRolesData: (state) => state.roles,
    getRoleStatuses: (state) => state.roleStatuses,
    getRolePermissions: (state) => state.rolePermissions,
  },
  mutations: {
    SET_ROLES_LIST(state, data) {
      state.roles = {
        list: data.list,
        page: data.pagination.page,
        total_pages: data.pagination.totalCountPage
      }
    },
    SET_ROLE_PERMISSIONS_LIST(state, data) {
      state.rolePermissions = data
    }
  },
  actions: {
    /**
     *
     * @param context
     * @param params
     * @returns {*}
     * List
     * {{REST_HOST}}/admin/api/roles/list?name=Test&page=1
     */
    loadRoles(context, params = {}) {
      return axios.get('/admin/api/roles/list', { params })
        .then((response) => {
          const data = response.data;

          if (data.data) {
            context.commit('SET_ROLES_LIST', data.data.roles);
          }

          return data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    /**
     *
     * @param context
     * @returns {*}
     * Available Permissions
     * GET
     * {{REST_HOST}}/admin/api/roles/permissions
     */
    loadRolePermissions(context) {
      return axios.get('/admin/api/roles/permissions')
        .then((response) => {
          const data = response.data;

          if (data.data) {
            context.commit('SET_ROLE_PERMISSIONS_LIST', data.data.list);
          }

          return data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    /**
     *
     * @param context
     * @param payload
     * @returns {*}
    */
    // POST
    // {{REST_HOST}}/admin/api/roles/role
    // {
    //     "name": "Test",
    //     "permissions": [
    //         "ACCESS_TO_ADMIN_PANEL",
    //         "ALL_USER_ASSETS"
    //     ],
    //     "status": 0
    // }
    createRole(context, payload = {}) {
      return axios.post('/admin/api/roles/role', payload)
        .then((response) => {
          const data = response.data;

          return data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    /**
     *
     * @param context
     * @param payload
     * @returns {*}
     */
    // PATCH
    // {{REST_HOST}}/admin/api/roles/role?id=
    // {
    //     "name": "Test",
    //     "permissions": [
    //         "ACCESS_TO_ADMIN_PANEL",
    //         "ALL_USER_ASSETS"
    //     ],
    //     "status": 0
    // }
    editRole(context, payload = {}) {
      const inputs = Object.assign({}, payload);
      delete inputs.id;

      return axios.patch(`/admin/api/roles/role?id=${payload.id}`, inputs)
        .then((response) => {
          const data = response.data;

          return data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    /**
     *
     * @param context
     * @param payload
     * @returns {*}
     */
    // PATCH 
    // {{REST_HOST}}/admin/api/roles/role?id=
    disableRole(context, payload = {}) {
      return axios.delete(`/admin/api/roles/role?id=${payload.id}`)
        .then((response) => {
          const data = response.data;

          return data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    /**
     *
     * @param context
     * @param payload
     * @returns {*}
    */
    // POST
    // {{REST_HOST}}/v3/mystery-boxes/admin/withdrawable-prize
    // {
    //     "box_id": 4, 
    //     "count": 100, 
    //     "currency_id": 451, 
    //     "amount": "100000000"
    // }
    // addMysteryBoxWithdrawablePrize(context, payload = {}) {
    //     return axios.post('/v3/mystery-boxes/admin/withdrawable-prize', payload)
    //         .then((response) => {
    //             const data = response.data;

    //             return data;
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    // },

    /**
     *
     * @param context
     * @param payload
     * @returns {*}
     */
    // PUT
    // {{REST_HOST}}/v3/mystery-boxes/admin/withdrawable-prize/:ID
    // {
    //     "box_id": 4, 
    //     "count": 100, 
    //     "currency_id": 451, 
    //     "amount": "100000000"
    // }
    // editMysteryBoxWithdrawablePrize(context, payload = {}) {
    //     const inputs = Object.assign({}, payload);
    //     delete inputs.id;

    //     return axios.put(`/v3/mystery-boxes/admin/withdrawable-prize/${payload.id}`, inputs)
    //         .then((response) => {
    //             const data = response.data;

    //             return data;
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    // },

    /**
     *
     * @param context
     * @param payload
     * @returns {*}
     */
    // DELETE 
    // {{REST_HOST}}/v3/mystery-boxes/admin/withdrawable-prize/:ID
    // deleteMysteryBoxPrize(context, payload = {}) {
    //     return axios.delete(`/v3/mystery-boxes/admin/prize/${payload.id}`)
    //         .then((response) => {
    //             const data = response.data;

    //             return data;
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    // },
  },
  modules: {}
};
