import axios from '../../utils/api';

export default {
    state: {
        statistics: {
            fees: {
                DEPOSIT: {},
                TRADE: {},
                TRADECOINS: {},
                TRADE_TOTALS_USDT_BY_DAY: [],
                TRADE_TOTALS_USDT_BY_MONTH: [],
                WITHDRAW: {},
            },
            coinFees: {
                list: {},
                totals: {},
            },
            calculatedProfit: {
                statistic: {
                    total: {},
                    by_currency: {}
                }
            },
        }
    },
    getters: {
        getStatistics: (state) => state.statistics,

        getStatusesVerificationCount: (state) => state.statistics.statusesVerificationCount,

        getStatisticsCountByTimeRegister: (state) => state.statistics.countByTimeRegister,
        getStatisticsCountByTimeVisit: (state) => state.statistics.countByTimeVisit,

        getStatisticsFees: (state) => state.statistics.fees,
        getStatisticsCoinFees: (state) => state.statistics.coinFees,

        getCalculatedProfit: (state) => state.statistics.calculatedProfit,
    },
    mutations: {
        SET_USER_STATISTICS_STATUSES_COUNT(state, data) {
            const counts = data.count_of_users_by_verification_statuses;

            const statusesVerificationCount = {};
            for (let i in counts) {
                statusesVerificationCount[counts[i].status_verification] = counts[i]._count
            }

            state.statistics = {
                ...state.statistics,
                statusesVerificationCount
            }
        },
        SET_USER_STATISTICS_SIGN_UP(state, data) {
            state.statistics = {
                ...state.statistics,
                countByTimeRegister: data,
            }
        },
        SET_USER_STATISTICS_VISIT(state, data) {
            state.statistics = {
                ...state.statistics,
                countByTimeVisit: data,
            }
        },
        SET_FEES_STATISTICS(state, data) {
            state.statistics = {
                ...state.statistics,
                fees: data,
            }
        },
        SET_COIN_FEES_STATISTICS(state, data) {
            state.statistics = {
                ...state.statistics,
                coinFees: {
                    list: data.list,
                    totals: data.totals,
                },
            }
        },
        SET_CALCULATED_PROFIT(state, data) {
            state.statistics = {
                ...state.statistics,
                calculatedProfit: data,
            }
        },
    },
    actions: {
        loadVerificationStatistic(context, params = {}) {
            // Do get data
            axios.get('/admin/api/verification/statistic', { params })
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        context.commit('SET_USER_STATISTICS_STATUSES_COUNT', data.data);
                    }

                    return true;
                })
                .catch((error) => {
                    console.log(error);
                    throw new Error('Error request');
                });
        },
        async loadUserStatisticSignUpLoginByMonth(context, params = {}) {
            // Array to store the last 12 months' dates
            let countByMonthSignUp = {};
            let countByMonthVisit = {};

            // Loop to calculate the dates of the last 12 months
            for (let i = 0; i < 12; i++) {
                const currentDate = new Date();

                // Set the current date to the first day of the current month
                currentDate.setDate(1);

                // Subtract 'i' months from the current date
                currentDate.setMonth(currentDate.getMonth() - i);

                // Calculate the time_from value
                const timeFrom = currentDate.toISOString();

                // Set the current date to the last day of the current month
                currentDate.setMonth(currentDate.getMonth() + 1);
                currentDate.setDate(0);

                const timeTo = currentDate.toISOString();

                // Do get data
                await axios.get('/admin/api/user/statistic', {
                    params: {
                        time_from: timeFrom,
                        time_to: timeTo,
                        ...params
                    },
                })
                    .then((response) => {
                        const data = response.data;

                        if (data.status) {
                            countByMonthSignUp[
                                currentDate.getMonth() + '-' + currentDate.getFullYear()
                            ] = data.data.count_by_time_register;
                            countByMonthVisit[
                                currentDate.getMonth() + '-' + currentDate.getFullYear()
                            ] = data.data.count_by_time_visit;
                        }

                        return true;
                    })
                    .catch((error) => {
                        console.log(error);
                        throw new Error('Error request');
                    });
            }

            context.commit('SET_USER_STATISTICS_SIGN_UP', countByMonthSignUp);
            context.commit('SET_USER_STATISTICS_VISIT', countByMonthVisit);
            return true;
        },
        loadFeesStatistics(context, params = {}) {
            // Do get data
            axios.get('/admin/api/statistics/list', { params })
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        context.commit('SET_FEES_STATISTICS', data.data.lists);
                    }

                    return true;
                })
                .catch((error) => {
                    console.log(error);
                    throw new Error('Error request');
                });
        },
        loadCoinFeesStatistics(context, params = {}) {
            // Do get data
            axios.get('/admin/api/statistics/fee', { params })
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        context.commit('SET_COIN_FEES_STATISTICS', data.data);
                    }

                    return true;
                })
                .catch((error) => {
                    console.log(error);
                    throw new Error('Error request');
                });
        },
        loadCalculatedProfit(context, params = {}) {
            // Do get data
            axios.post('/admin/api/statistics/calculated-profit', { params })
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        context.commit('SET_CALCULATED_PROFIT', data.data); 
                    }

                    return true;
                })
                .catch((error) => {
                    console.log(error);
                    throw new Error('Error request');
                });
        }
    },
    modules: {}
};
