import {createApp} from 'vue'

/**
 *
 */
import 'bootstrap';
import Modal from "vue-bs-modal";
import ToastPlugin from 'vue-toast-notification';
import SimpleTypeahead from 'vue3-simple-typeahead';
import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css'; //Optional default CSS

/**
 *
 */
import App from './App.vue'
import router from './router';
import store from './store';
import i18n from './translation';

/**
 * Import Font Awesome Icon
 */
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {library} from "@fortawesome/fontawesome-svg-core";
import {
    faAngleDoubleDown,
    faAngleDoubleUp,
    faCopy,
    faCheck,
    faXmark,
    faTrashCan,
    faLink,
    faGauge,
    faFileInvoice,
    faFileInvoiceDollar,
    faUnlock,
    faPlug,
    faMoneyBillTransfer,
    faWallet,
    faGear,
    faMoneyBillTrendUp,
    faPuzzlePiece,
    faSection,
    faEdit,
    faEye,
    faChevronUp,
    faChevronDown,
    faFileExcel,
    faLock,
    faLockOpen,
    faSpinner,
    faPlus,
    faFile,
    faFilePdf,
    faDownload,
    faExpandAlt,
    faCompressAlt,
    faFileCsv,
    faBoxArchive,
    faTrashArrowUp,
    faFileZipper,
    faSignature,
    faSquareCheck as fasFaSquareCheck,
    faCircleDollarToSlot,
    faWrench,
    faRotateLeft,
    faFloppyDisk,
    faCodeBranch,
    faCircleInfo
} from '@fortawesome/free-solid-svg-icons';

import {
    faUser as farFaUser,
    faBell as farFaBell,
    faEnvelope as farFaEnvelope,
    faCalendarDays as farFaCalendarDays,
    faSquareMinus,
    faSquarePlus,
    faSquareCheck as farFaSquareCheck,
    faSquare as farFaSquare,
    faCircleStop as faFarCircleStop,
    faQuestionCircle
} from '@fortawesome/free-regular-svg-icons';

library.add(
    faAngleDoubleDown,
    faAngleDoubleUp,
    faTrashCan,
    faCopy,
    faCheck,
    faXmark,
    faLink,
    faGauge,
    faFileInvoice,
    faFileInvoiceDollar,
    faUnlock,
    faPlug,
    faMoneyBillTransfer,
    faWallet,
    faGear,
    faMoneyBillTrendUp,
    faPuzzlePiece,
    faSection,
    faEdit,
    faEye,
    faLock,
    faLockOpen,
    faChevronUp,
    faChevronDown,
    faSpinner,
    faFileExcel,
    farFaUser,
    farFaBell,
    farFaEnvelope,
    farFaCalendarDays,
    faPlus,
    faFile,
    faFilePdf,
    faDownload,
    faExpandAlt,
    faCompressAlt,
    faSquareMinus,
    faSquarePlus,
    faFileCsv,
    faBoxArchive,
    faTrashArrowUp,
    faFileZipper,
    faSignature,
    fasFaSquareCheck,
    farFaSquareCheck,
    farFaSquare,
    faCircleDollarToSlot,
    faFarCircleStop,
    faWrench,
    faQuestionCircle,
    faRotateLeft,
    faFloppyDisk,
    faCodeBranch,
    faCircleInfo
);

/**
 * Create APP and init all components
 */
const app = createApp(App)

app.config.globalProperties.$VUE_APP_TITLE = process.env.VUE_APP_TITLE ?? process.env.VUE_APP_COMPANY_NAME ?? 'Admin Panel';

router.onError(error => {
    if (/loading chunk \d* failed./i.test(error.message)) {
        window.location.reload();
    }
});

app.use(router)
.use(store)
.use(ToastPlugin, {
    position: 'top',
    queue: true
})
.use(i18n)
.use(Modal)
.use(SimpleTypeahead)
.component('fa-icon', FontAwesomeIcon)
.mount('#app')
