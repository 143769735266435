import axios from '../../utils/api';

export default {
    state: {
        leaderboard: {
            list: [],
            page: 0,
            total_pages: 0
        }
    },
    getters: {
        getLeaderboardData: (state) => state.leaderboard,
    },
    mutations: {
        SET_LEADERBOARD_LIST(state, data) {
            state.leaderboard = {
                list: data.currentPageResult,
                page: data.page,
                total_pages: data.totalPages
            }
        }
    },
    actions: {
        loadLeaderboardList(context, params = {}) {
            // Do get data
            return axios.get('/v3/leaderboard/full', {params})
                .then((response) => {
                    const data = response.data;

                    if (data.data) {
                        context.commit('SET_LEADERBOARD_LIST', data.data);
                    }

                    return data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        addLeaderboardPoint(context, params = {}) {
            // Do get data
            return axios.post(`/v3/leaderboard/give-to-user`, params)
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    return error.data;
                });
        }
    },
    modules: {}
};
