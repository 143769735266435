import axios from '../../utils/api';
import {buildQueryString} from "@/utils/BuildQueryString";

export default {
    state: {
        balances: {
            list: [],
            pagination: {
                page: 0,
                totalCountPage: 0
            },
            total_count: 0
        },
        balances_all: {
            list: [],
            pagination: {
                page: 0,
                totalCountPage: 0
            },
            total_count: 0
        },
        balances_by_types: {
            spot: [],
            margin: [],
            funding: []
        },
        depositAddresses: {},
        depositTypes: {
            spot: "Fiat and Spot",
            margin: "Margin",
            funding: "Funding"
        },
    },
    getters: {
        getUserBalances: (state) => state.balances,
        getUserBalancesByType: (state) => state.balances_by_types,
        getUserBalancesAll: (state) => state.balances_all,
        getDepositAddresses: (state) => state.depositAddresses,
    },
    mutations: {
        SET_BALANCES(state, data) {
            state.balances = data;
        },

        SET_BALANCES_BY_TYPES(state, data) {
            state.balances_by_types[data.type] = data.data;
        },

        SET_BALANCES_ALL(state, data) {
            state.balances_all = data;
        },

        SET_DEPOSIT_ADDRESSES(state, data) {
            state.depositAddresses = data;
        },
    },
    actions: {
        /**
         * @param context
         * @param payload
         * @returns {*}
         */
        loadWalletsBalances(context, payload = {}) {
            const queryString = buildQueryString(payload);

            // Do get data
            return axios.get(`/admin/api/user/balances?${queryString}`)
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        context.commit('SET_BALANCES', data.data.deposit);

                        context.commit('SET_TOTAL_BALANCE', data.data.totalBalance);
                    }

                    return data;
                })
                .catch((error) => {
                    console.log(error);
                    throw new Error('Error request');
                });
        },

        /**
         * @param context
         * @param payload
         * @returns {Promise<void>}
         */
        async loadWalletsBalancesByTypes(context, payload = {}) {
            ['spot', 'margin', 'funding'].forEach(type => {
                payload.Deposit = {
                    deposit_type: type
                };

                const queryString = buildQueryString(payload);

                // Do get data
                return axios.get(`/admin/api/user/balances?${queryString}`)
                    .then((response) => {
                        const data = response.data;

                        if (data.status) {
                            context.commit('SET_BALANCES_BY_TYPES', { type, data: data.data.deposit });

                            context.commit('SET_TOTAL_BALANCE', data.data.totalBalance);
                        }

                        return data;
                    })
                    .catch((error) => {
                        console.log(error);
                        throw new Error('Error request');
                    });
            })
        },

        /**
         * @param context
         * @param payload
         * @returns {Promise<*>}
         */
        async loadWalletsBalancesAll(context, payload = {}) {
            const queryString = buildQueryString(payload);

            try {
                // send request to API
                const response = await axios.get(`/admin/api/user/balances-all?${queryString}`);

                // get data
                const data = response.data;

                // save response
                if (data.status) {
                    context.commit('SET_BALANCES_ALL', data.data.deposit);
                }

                // Send new request
                // await this.dispatch('loadWalletsBalancesAll');

                return data;
            } catch (error) {
                console.log(error);
                throw new Error('Error request');
            }
        },

        /**
         * @param context
         * @param payload
         * @returns {*}
         */
        async loadWalletsBalancesAllReport(context, payload = {}) {
            const queryString = buildQueryString(payload);

            // Do get data
            return axios.get(`/admin/api/user/balances-all?${queryString}`)
                .then((response) => {
                    const data = response.data;

                    return data.data.csv_link;
                })
                .catch((error) => {
                    console.log(error);
                    throw new Error('Error request');
                });
        },

        /**
         * @param context
         * @param payload
         * @returns {*}
         */
        loadDepositAddresses(context, payload = {}) {
            const inputs = Object.assign({}, payload);
            delete inputs.id;

            // Do get data
            return axios.post(`/admin/api/user/deposit-addresses?user_id=${payload.id}`, inputs)
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        context.commit('SET_DEPOSIT_ADDRESSES', data.data.addresses.list[payload.id]);
                    }

                    return data;
                })
                .catch((error) => {
                    console.log(error);
                    throw new Error('Error request');
                });
        },

        /**
         *
         * @param context
         * @param payload
         * @returns {*}
         */
        systemTransfer(context, payload = {}) {
            return axios.post(`/admin/api/transactions/system-transfer`, payload)
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    },
    modules: {}
};
