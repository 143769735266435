import axios from '../../utils/api';
import {buildQueryString} from "@/utils/BuildQueryString";

export default {
    state: {
        currencies: {
            list: [],
            pagination: {
                page: 0,
                totalCountPage: 0
            },
            total_count: 0,
        },
        currencies_loading: false,
        currencyByIso3: null,

        allCurrencies: {
            list: [],
            pagination: {
                page: 0,
                totalCountPage: 0
            },
            total_count: 0,
        },
        allPairs: {
            list: [],
            pagination: {
                page: 0,
                totalCountPage: 0
            },
            total_count: 0,
        },
        enabledCurrencies: [],
        enabledPairs: [],

        totalProfit: {
            by_currency: {}
        }
    },
    getters: {
        getCurrencies: (state) => state.currencies,
        getAllCurrencies: (state) => state.allCurrencies,
        getAllPairs: (state) => state.allPairs,
        getEnabledCurrencies: (state) => state.enabledCurrencies,
        getEnabledPairs: (state) => state.enabledPairs,
        getCurrencyByIso3: (state) => state.currencyByIso3,
        getPairById: (state) => state.pairById,
        getCurrenciesLoading: (state) => state.currencies_loading,
        getTotalProfit: (state) => state.totalProfit,
    },
    mutations: {
        SET_CURRENCIES(state, data) {
            state.currencies = data
        },
        SET_CURRENCIES_LOADING(state, data) {
            state.currencies_loading = data
        },
        SET_ALL_CURRENCIES(state, data) {
            state.allCurrencies = data
        },
        SET_ALL_PAIRS(state, data) {
            state.allPairs = data
        },
        SET_ENABLED_CURRENCIES(state, data) {
            state.enabledCurrencies = data
        },
        SET_ENABLED_PAIRS(state, data) {
            state.enabledPairs = data
        },
        SET_CURRENCY_BY_ISO3(state, data) {
            state.currencyByIso3 = data
        },
        SET_PAIR_BY_ID(state, data) {
            state.pairById = data
        },
        SET_TOTAL_PROFIT(state, data) {
            state.totalProfit = data
        }
    },
    actions: {
        loadCurrencies(context, params = {}) {
            context.commit('SET_CURRENCIES_LOADING', true);

            // Do get data
            return axios.get('/admin/api/currencies/list', {params})
                .then((response) => {
                    const data = response.data;

                    context.commit('SET_CURRENCIES_LOADING', false);

                    if (data.status) {
                        context.commit('SET_CURRENCIES', data.data.currencies);

                        // list of all available coins
                        context.commit('SET_ALL_CURRENCIES', data.data.listAllCurrencies);

                        // list of all available pairs
                        context.commit('SET_ALL_PAIRS', data.data.listAllPairs);

                        // array of included coins on the project
                        context.commit('SET_ENABLED_CURRENCIES', data.data.project_currencies);

                        // array of enabled pairs on the project
                        context.commit('SET_ENABLED_PAIRS', data.data.project_pairs);
                    }

                    return data;
                })
                .catch((error) => {
                    console.log(error);
                    throw new Error('Error request');
                });
        },

        loadCurrencyByIso3(context, iso3 = null) {
            // Do get data
            return axios.get(`/admin/api/currencies/list?iso3=${iso3}`)
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        context.commit('SET_CURRENCY_BY_ISO3', data.data.currencies.list.find(v => v.iso3 === iso3));
                    }

                    return data;
                })
                .catch((error) => {
                    console.log(error);
                    throw new Error('Error request');
                });
        },

        updateCurrencyFee(context, params = {currency_id: null, network_type: null, data: null}) {
            // Do send data
            return axios.post(`/admin/api/currencies/update-fee?currency_id=${params.currency_id}&network_type=${params.network_type}`, params.data)
                .then(r => {
                    return r.data;
                })
                .catch((r) => {
                    console.error(r);
                    throw new Error('Error request');
                });
        },
        updateCurrencyNetworkData(context, params = {currency_id: null, network_type: null, data: null}) {
            // Do send data
            return axios.post(`/admin/api/currencies/update-network?currency_id=${params.currency_id}&network_type=${params.network_type}`, params.data)
                .then(r => {
                    return r.data;
                })
                .catch((r) => {
                    console.error(r);
                    throw new Error('Error request');
                });
        },

        /**
         * @param context
         * @param currency_id
         * @returns {*}
         */
        getCurrencyById(context, currency_id = null) {
            return axios.get(`/admin/api/currencies/currency?id=${currency_id}`)
                .then(r => {
                    if (r.data.status) {
                        context.commit('SET_CURRENCY_BY_ISO3', r.data.data.currency);
                    }

                    return r.data;
                })
                .catch((r) => {
                    return r.data
                });
        },
        /**
         * @param context
         * @param params
         * @returns {*}
         */
        patchCurrency(context, params = {currency_id: null, data: null}) {
            // Do send data
            return axios.patch(`/admin/api/currencies/currency?id=${params.currency_id}`, params.data)
                .then(r => {
                    return r.data;
                })
                .catch((r) => {
                    console.error(r);
                    throw new Error('Error request');
                });
        },
        /**
         * @param context
         * @param data
         * @returns {*}
         */
        createCurrency(context, data) {
            // Do send data
            return axios.post(`/admin/api/currencies/currency`, data, {asFormData: true})
                .then(r => {
                    return r.data;
                })
                .catch((r) => {
                    console.error(r);
                    throw new Error('Error request');
                });
        },
        updateCurrencyIcon(context, payload = {currency_id: null, data: null}) {
            // Do send data
            return axios.post(
                    `/admin/api/currencies/update-currency-icon?id=${payload.currency_id}`,
                    payload.data,
                    { asFormData: true }
                )
                .then(r => {
                    return r.data;
                })
                .catch((r) => {
                    console.error(r);
                    throw new Error('Error request');
                });
        },
        patchCurrenciesPair(context, params = {pair_id: null, data: null}) {
            // Do send data
            return axios.patch(`/admin/api/currencies/pair?id=${params.pair_id}`, params.data)
                .then(r => {
                    return r.data;
                })
                .catch((r) => {
                    console.error(r);
                    throw new Error('Error request');
                });
        },
        createCurrenciesPair(context, data) {
            // Do send data
            return axios.post(`/admin/api/currencies/pair`, data)
                .then(r => {
                    return r.data;
                })
                .catch((r) => {
                    console.error(r);
                    throw new Error('Error request');
                });
        },

        /**
         * on / off coin (status 0 - off, 1 - on)
         * ?currency_id=2&status=1
         *
         * on / off pair (status 0 - off, 1 - on)
         * ?pair_id=7551&status=1
         *
         * @param context
         * @param params
         * @returns {*}
         */
        updateCurrency(context, params = {}) {
            const queryString = buildQueryString(params);

            // Do send data
            return axios.post(`/admin/api/currencies/update-currency?${queryString}`, {})
                .then(r => {
                    return r.data;
                })
                .catch((r) => {
                    console.error(r);
                    throw new Error('Error request');
                });
        },
        loadCurrencyPairSettings(context, params = {}) {
            // Do send data
            return axios.get(`/admin/api/currencies/currency-pair-settings?pair_id=${params.pair_id}`)
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        context.commit('SET_PAIR_BY_ID', data.data.currency_pair);
                    }

                    return data.data.currency_pair;
                })
                .catch((r) => {
                    console.error(r);
                    throw new Error('Error request');
                });
        },
        updateCurrencyPairSettings(context, params = {}) {
            // Do send data
            return axios.post(`/admin/api/currencies/currency-pair-settings?pair_id=${params.pair_id}`, params.payload)
                .then(r => {
                    return r.data;
                })
                .catch((r) => {
                    console.error(r);
                    throw new Error('Error request');
                });
        },
        createTradePairWithToken(context, payload = {}) {
            // Do send data
            return axios.post(`/admin/api/currencies/create-trade-pair-with-token`, payload)
                .then(r => {
                    return r.data;
                })
                .catch((r) => {
                    return r.data;
                })
        },
        runPairTrading(context, payload = {}) {
            // Do send data
            return axios.post(`/admin/api/currencies/run-pair-trading`, { pair_id: payload.pair_id })
                .then(r => {
                    const { status, data } = r.data;

                    if (status) {
                        return r.data;
                    } else {
                        throw new Error(Object.values(data.errors ?? [])[0]);
                    }
                })
                .catch((r) => {
                    console.error(r);
                    throw new Error(r.message);
                });
        },
        loadTotalProfit(context, params = {}) {
            // Do send data
            return axios.get(`/admin/api/total-profit`, { params })
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        context.commit('SET_TOTAL_PROFIT', data.data);
                    }

                    return data;
                })
                .catch((r) => {
                    console.error(r);
                    throw new Error('Error request');
                });
        },
        totalProfitPayForCurrency(context, params = {}) {
            // Do send data
            return axios.get(`/admin/api/total-profit/pay-for-currency`, { params }) // currency_id
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        context.commit('SET_TOTAL_PROFIT', data.data);
                    }

                    return data;
                })
                .catch((r) => {
                    console.error(r);
                    throw new Error('Error request');
                });
        },
        // totalProfitPay(context, params = {}) {
        //     // Do send data
        //     return axios.get(`/admin/api/total-profit/pay`, { params })
        //         .then((response) => {
        //             const data = response.data;

        //             if (data.status) {
        //                 context.commit('SET_PAIR_BY_ID', data.data.currency_pair);
        //             }

        //             return data.data.currency_pair;
        //         })
        //         .catch((r) => {
        //             console.error(r);
        //             throw new Error('Error request');
        //         });
        // },
    },
    modules: {}
};
