import axios from 'axios';
import encrypt from './Encrypt';
import store from '../store';
import { isOnLoginPage, redirectToLogin } from '@/router';

const TK = 'token';
const getToken = () => localStorage.getItem(TK);
const setToken = (token) => localStorage.setItem(TK, token);

/**
 * @param axios
 * @returns {*}
 */
const configure = (axios) => {
    axios.defaults.debug = true;

    axios.defaults.baseURL = process.env.VUE_APP_API_PATH;

    axios.interceptors.request.use((config) => {
        const token = getToken();
        if (token && !config.url.startsWith('/api/default')) {
            config.headers['login-token'] = token;
        }

        if (!config.data) {
            config.data = {};
        }

        // If POST OR PUT OR PATCH
        if (config.method === 'post' || config.method === 'put' || config.method === 'patch') {
            // Set timestamp for unique request_id
            const randomInt = (Math.floor(Math.random() * (999 - 100)) + 100);
            config.data.request_id = new Date().getTime().toString() + randomInt.toString();

            // Convert to FormData
            if (config.asFormData) {
                const requestData = { ...config.data };

                // Create new FormData object
                const formData = new FormData();

                // Add property requestData property to formData
                for (const key0 in requestData) {
                    const value = requestData[key0];

                    // Check if exist any file
                    if (value instanceof File) {
                        formData.append(key0, value);
                        delete requestData[key0];
                        continue;
                    }

                    // Check is object
                    if (typeof value === 'object') {
                        for (const key1 in value) {
                            const subValue = value[key1];

                            if (typeof subValue === 'object') {
                                for (const key2 in subValue) {
                                    formData.append(`${key0}[${key1}][${key2}]`, subValue[key2]);
                                }
                            } else {
                                formData.append(`${key0}[${key1}]`, value[key1]);
                            }
                        }
                    } else {
                        formData.append(key0, value);
                    }
                }

                // Update config.data до formData
                config.data = formData;
            }

            // Add X-Auth-Sign
            if (config.config && config.config.headers) {
                config.headers['X-Auth-Sign'] = config.config.headers['X-Auth-Sign'];
            } else {
                if (!config.asFormData) {
                    config.headers['X-Auth-Sign'] = encrypt(config.data);
                } else {
                    const formDataObject = {};
                    config.data.forEach((value, key) => {
                        formDataObject[key] = value;
                    })

                    config.headers['X-Auth-Sign'] = encrypt(formDataObject);
                }
            }
        }

        return config;
    }, (error) => {
        // console.error('Request error:', error);
        return Promise.reject(error);
    });

    axios.interceptors.response.use((response) => {
        // console.log('Response was received:', response);
        if (response.data.token) {
            setToken(response.data.token)
        }

        return response;
    }, async (err) => {
        if (err.response && err.response.status === 401 && !isOnLoginPage()) {
            // Do logout
            const result = await store.dispatch('doLogout');

            // Do redirect to home page
            if (result) {
                redirectToLogin();
            }
        }

        if (err.response && err.response.status === 402) {
            alert('402 error');
        }

        if (err.response && err.response.status === 403) {
            console.log("You don't have access to these resource")
        }

        if (err.response && err.response.status === 409) {
            console.log('Back-end maintenance...');
        }

        if (err.response && err.response.status === 500) {
            // {data:err.response.data, url:err.response.config.url, method:err.response.config.method}
            // vuex.commit('INTERNAL_ERROR', true)
        }

        return Promise.reject(err.response);
    });

    return axios;
};

export default configure(axios);
